import React, { useEffect, useState } from "react";
import TableToolbar from "../../../../components/Table/TableToolbar";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import { Button, Container, TextField } from "@material-ui/core";
import { CSVLink, CSVDownload } from "react-csv";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import styles from "../../Report.module.scss";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import moment from "moment-timezone";
import { getClientsList } from "../../../../services/apiservices/client";
import { getTimesheetListReport } from "../../../../services/apiservices/timesheets";
import Popover from "@mui/material/Popover";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as Excel from 'exceljs';
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveTable from "../../../../components/ReportsComponent/ClientReports/ResponsiveClientTable";
import { useMediaQuery } from "@mui/material";

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <div className={styles.Action}>
                <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
                    <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
                </Link>
                <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
            </div>
            <Popover
                id="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    // vertical: 'left',
                    horizontal: "left",
                }}
                transformOrigin={{
                    // vertical: 'left',
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",

                        // padding: '50px', // Add some padding
                    },
                }}
            >
                <div className={styles.box}>
                    <div className={styles.box2}>Download</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            left: 20,
                        }}
                    >
                        <div>
                            <CSVLink
                                data={clientsListCSV}
                                filename={`WorkSiteReport_${rcellItem.clientName}_${startDate}_${endDate}.csv`}
                                className={styles.Link}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    setDownloadCSVData(event, done, rcellItem)
                                }}
                            >
                                csv
                            </CSVLink>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadExcel(rcellItem) }}>
                                <u className={styles.Link}>Excel</u>
                            </a>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadPDF(rcellItem) }}>
                                <u className={styles.Link}>Pdf</u>
                            </a>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};
function WorkSiteReport(props) {
    const csvHeaders = [
        "Client Name",
        "Date",
        "Employee Name",
        "Start Time",
        "End Time",
        "Shift Name",
        "Check In",
        "Check Out",
        "Completed",
        "Pending",
    ];
    const [clientsList, setClientList] = useState([]);
    const [clientsListMaster, setClientsListMaster] = useState([]);
    const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [isLoading, setIsLoading] = useState(false);
    const [clientName, setClientName] = useState("All");
    const [clientNameList, setClientNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [empName, setEmpName] = useState("All");
    const [employeNameList, setEmployeNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [detailedView, setDetailedView] = useState(null);

    const getColumns = () => {
        const columns = [
            {
                id: "clientName",
                numeric: false,
                disablePadding: false,
                label: "Client Name",
                isSortable: false,
            },
            {
                id: "appointmentDate",
                numeric: false,
                disablePadding: false,
                label: "Date",
                isSortable: false,
            },
            {
                id: "employeeName",
                numeric: false,
                disablePadding: false,
                label: "Employee Name",
                isSortable: false,
            },
            {
                id: "startTime",
                numeric: false,
                disablePadding: false,
                label: "Start Time",
                isSortable: false,
            },
            {
                id: "endTime",
                numeric: false,
                disablePadding: false,
                label: "End Time",
                isSortable: false,
            },
            {
                id: "shiftName",
                numeric: false,
                disablePadding: false,
                label: "Shift Name",
                isSortable: false,
            },
            {
                id: "checkInDateTime",
                numeric: false,
                disablePadding: false,
                label: "Check In",
                isSortable: false,
            },
            {
                id: "checkOutDateTime",
                numeric: false,
                disablePadding: false,
                label: "Check Out",
                isSortable: false,
            },
            {
                id: "complete",
                numeric: false,
                disablePadding: false,
                label: "Completed",
                isSortable: false,
            },
            {
                id: "pending",
                numeric: false,
                disablePadding: false,
                label: "Pending",
                isSortable: false,
            },
            {
                id: "Action",
                numeric: false,
                disablePadding: false,
                label: "Action",
                isSortable: false,
            },
        ];
        if (isMobile || isLandscape) {
            return columns.filter(col => col.id !== "clientName" && col.id !== "appointmentDate" && col.id !== "Action");
        }
        return columns;
    };

    const getDatatoDownload = (rcellItem) => {
        let clientCSV = [];
        if (!Array.isArray(rcellItem)) {
            clientCSV = clientsList?.filter(
                (item) => item.clientNameId === rcellItem.clientNameId
            );
            clientCSV = clientCSV?.map((item) => {
                return [
                    item?.clientName,
                    item?.appointmentDate,
                    item?.employeeName,
                    item?.startTime,
                    item?.endTime,
                    item?.shiftName[0],
                    item?.checkInDateTime,
                    item?.checkOutDateTime,
                    item?.complete,
                    item?.pending,
                ];
            });
        } else {
            clientCSV = rcellItem?.map((item) => {
                return [
                    item?.clientName,
                    item?.appointmentDate,
                    item?.employeeName,
                    item?.startTime,
                    item?.endTime,
                    item?.shiftName[0],
                    item?.checkInDateTime,
                    item?.checkOutDateTime,
                    item?.complete,
                    item?.pending,
                ];
            });
        }
        return clientCSV;
    }

    const getDatatoFullDownload = (rcellItem) => {
        let clientCSV;
        if (rcellItem) {
            clientCSV = clientsList?.filter(
                (item) => item.clientNameId === rcellItem.clientNameId
            );
            return [
                rcellItem?.clientName,
                rcellItem?.appointmentDate,
                rcellItem?.employeeName,
                rcellItem?.startTime,
                rcellItem?.endTime,
                rcellItem?.shiftName[0],
                rcellItem?.checkInDateTime,
                rcellItem?.checkOutDateTime,
                rcellItem?.complete,
                rcellItem?.pending,
            ];
        } else {
            return [
                rcellItem?.clientName,
                rcellItem?.appointmentDate,
                rcellItem?.employeeName,
                rcellItem?.startTime,
                rcellItem?.endTime,
                rcellItem?.shiftName[0],
                rcellItem?.checkInDateTime,
                rcellItem?.checkOutDateTime,
                rcellItem?.complete,
                rcellItem?.pending,
            ];
        }
    }

    const downloadForExcel = async (rcellItem, emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Work Site Report');
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `WorkSiteReport_${rcellItem[0]?.clientName}_${startDate}_${endDate}`;
        } else {
            fileName = `WorkSiteReport_${rcellItem?.clientName}_${startDate}_${endDate}`;
        }
        const clientCSV = getDatatoDownload(rcellItem);
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }

    const downloadForPDF = async (rcellItem, emailSend) => {
        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `WorkSiteReport_${rcellItem[0]?.clientName}_${startDate}_${endDate}`;
        } else {
            fileName = `WorkSiteReport_${rcellItem?.clientName}_${startDate}_${endDate}`;
        }
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    }

    const setDownloadCSVData = (event, done, rcellItem) => {
        const clientCSV = getDatatoDownload(rcellItem);

        const reportName = "Work Site Report";
        const clientName = Array.isArray(rcellItem) ? `${rcellItem[0]?.clientName}` : `${rcellItem?.clientName}`;
        const reportPeriod = `${startDate} to ${endDate}`;

        // Create the report details rows
        const reportDetails = [
            [`Client Name: ${clientName}`],
            [`Report Name: ${reportName}`],
            [`Report Period: ${reportPeriod}`],
            [],
            [...csvHeaders],
            ...clientCSV
        ];

        // Set the CSV data
        setClientListCSV(reportDetails);
        done();
    };


    const downloadForFullPDF = async (emailSend) => {
        const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));
        let clientNamefordwonLoad = 'All'

        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
        }
        let fileName = `WorkSiteReport_${clientNamefordwonLoad}_${startDate}_${endDate}`
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    }

    const downloadForFullExcel = async (emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Work Site Report');
        let clientNamefordwonLoad = 'All'

        const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));

        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
        }
        let fileName = `WorkSiteReport_${clientNamefordwonLoad}_${startDate}_${endDate}`
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }


    const handleEmailIconClick = async (rcellItem) => {
        const emailSend = true;
        const excelBlob = await downloadForExcel(rcellItem, emailSend);
        let name;
        if (Array.isArray(rcellItem)) {
            name = rcellItem[0]?.clientName;
        } else {
            name = rcellItem?.clientName;
        }
        localStorage.setItem("FileName", "WorkSiteReport");

        localStorage.setItem("ReportsName", "Client Report");

        localStorage.setItem("Name", name);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        const pdfBlob = await downloadForPDF(rcellItem, emailSend);
        localStorage.setItem("pdfData", pdfBlob);
        setIsLoading(true);

        // Convert the Excel blob to a base64-encoded string
        const reader = new FileReader();
        reader.readAsDataURL(excelBlob);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("emailData", base64Data);

            // Navigate to the email reports page
            navigate("/emailreports");
        };
    };

    const handelEmailFullReport = async () => {
        const emailSend = true;
        localStorage.setItem("FileName", "WorkSiteReport");

        localStorage.setItem("ReportsName", "Client Report");

        let clientNamefordwonLoad = 'All'

        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
        }
        localStorage.setItem("Name", clientNamefordwonLoad);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);
        setIsLoading(true);
        const fullReport = await downloadForFullPDF(emailSend);
        localStorage.setItem("fullReport", fullReport);

        const fullExcelReport = await downloadForFullExcel(emailSend);
        const reader = new FileReader();
        reader.readAsDataURL(fullExcelReport);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("fullExcelReport", base64Data);

            navigate("/emailreports");
        }
    }

    const handlePdfClick = async (records, emailSend) => {
        await downloadForPDF(records, emailSend);
    }

    const handleExcelClick = async (records, emailSend) => {
        await downloadForExcel(records, emailSend);
    }

    const handleCSvClick = async (event, done, records) => {
        await setDownloadCSVData(event, done, records);
    };

    const handelEmailClicks = async (records) => {
        await handleEmailIconClick(records);
    }


    const handleclientNameChange = (e) => {
        const str = e.target.value;
        setClientName(str);
        setClientList(() =>
            clientsListMaster.filter((itme) => {
                let returnFlg = true;
                if (empName !== "All") {
                    returnFlg = itme.employeNameId === empName;
                }
                if (str !== "All" && returnFlg) {
                    returnFlg = itme.clientNameId === str;
                }
                return returnFlg;
            })
        );
    };

    const handleEmpNameChange = (e) => {

        const str = e.target.value;

        setEmpName(str);
        setClientList(() =>
            clientsListMaster.filter((itme) => {
                let returnFlg = true;
                if (clientName !== "All") {
                    returnFlg = itme.clientNameId === clientName;
                }
                if (str !== "All" && returnFlg) {
                    returnFlg = itme.employeNameId === str;
                }
                return returnFlg;
            })
        );
    };

    useEffect(() => {
        let paramStartDate = "";
        let paramEndDate = "";

        paramStartDate = startDate;
        paramEndDate = endDate;

        getReportListData(paramStartDate, paramEndDate);
    }, [startDate, endDate]);




    const mapWithClientIds = async (clientIds, timeSheet) => {
        getClientsList(clientIds, (result) => {
            const clients = result.map((item) => {
                return { value: item.id, label: item.clientName };
            });
            setClientNameList([{ label: "All", value: "All" }, ...clients]);

            const finaleData = timeSheet.map((resItem) => {
                const userTimeSheet = result.filter(
                    (itm) => itm.id === resItem.clientId
                );

                const taskTitles = Object.values(resItem.tasks).map(
                    (task) => task.title
                );
                const task = Object.values(resItem.tasks).map((task) => task.tasksList);
                const completedTask = task[0].filter((task) => task.completed === true);

                const empNames = resItem?.apoointmentDetails?.calendar;
                setEmployeNameList([{ label: "All", value: "All" }, { value: resItem?.userId, label: empNames }]);

                resItem["clientName"] = userTimeSheet[0].clientName;
                resItem["clientNameId"] = userTimeSheet[0].id;
                resItem["date"] = userTimeSheet[0].appointmentDate;
                resItem["shiftName"] = taskTitles;
                resItem["employeeName"] = empNames;
                resItem["employeNameId"] = resItem?.userId;
                resItem["startTime"] = resItem?.apoointmentDetails?.time;
                resItem["endTime"] = resItem?.apoointmentDetails?.endTime;
                resItem["checkInDateTime"] = resItem?.checkInDateTime
                    ? moment.unix(resItem?.checkInDateTime?.seconds).format("hh:mm a") : "-";
                resItem["checkOutDateTime"] = resItem?.checkOutDateTime
                    ? moment.unix(resItem?.checkOutDateTime?.seconds).format("hh:mm a") : "-";
                resItem["complete"] = completedTask.length;
                resItem["pending"] = task[0].length - completedTask.length;
                return resItem;
            });



            let sortedData = sortingFunction(finaleData);
            setClientsListMaster(sortedData);

            setClientList(() =>
                sortedData?.filter((itme) => {
                    let returnFlg = true;
                    if (clientName !== "All") {
                        returnFlg = itme.clientNameId === clientName;
                    }
                    if (empName !== "All" && returnFlg) {
                        returnFlg = itme.employeNameId === empName;
                    }
                    return returnFlg;
                })
            );
        });
    };

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.clientName < b.clientName) return -1;
            if (a.clientName > b.clientName) return 1;
            // If clientName is the same, sort by appointmentDate
            if (a.appointmentDateTime < b.appointmentDateTime) return -1;
            if (a.appointmentDateTime > b.appointmentDateTime) return 1;
            return 0;
        });

        return result;
    };

    const getReportListData = async (startDt, endDt) => {
        const param = {
            startDate: `${moment(startDt).format("YYYY-MM-DD")}T00:00:00`,
            endDate: `${moment(endDt).format("YYYY-MM-DD")}T23:59:59`,
        };

        getTimesheetListReport(
            param,
            (result) => {
                setIsLoading(false);
                const clientIds = [];
                for (let i = 0; i < result.length; i++) {
                    if (clientIds.indexOf(result[i].clientId) === -1) {
                        clientIds.push(result[i].clientId);
                    }
                }

                mapWithClientIds(clientIds, result);
            },
            (err) => {
                setIsLoading(false);
                console.log("err>>", err);
            }
        );
    };


    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "clientName":
                if (
                    rowindex !== 0 &&
                    clientsList[rowindex - 1].clientName !== rcellItem.clientName
                ) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else if (rowindex === 0) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else {
                    return "";
                }
                break;
            // case "employeeName":
            //     if (
            //         rowindex !== 0 &&
            //         clientsList[rowindex - 1].employeeName !== rcellItem.employeeName
            //     ) {
            //         return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
            //     } else if (rowindex === 0) {
            //         return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
            //     } else {
            //         return "";
            //     }
            //     break;
            case "Action":
                if (isMobile || isLandscape) {
                    if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    if (
                        rowindex !== 0 &&
                        clientsList[rowindex - 1].clientName !== rcellItem.clientName
                    ) {
                        return <ActionRender rcellItem={rcellItem}
                            clientsListCSV={clientsListCSV}
                            setDownloadCSVData={setDownloadCSVData}
                            downloadPDF={downloadForPDF}
                            downloadExcel={downloadForExcel}
                            startDate={startDate}
                            endDate={endDate}
                            handleEmailIconClick={handleEmailIconClick}
                        />;
                    } else if (rowindex === 0) {
                        return <ActionRender rcellItem={rcellItem}
                            clientsListCSV={clientsListCSV}
                            setDownloadCSVData={setDownloadCSVData}
                            downloadPDF={downloadForPDF}
                            downloadExcel={downloadForExcel}
                            startDate={startDate}
                            endDate={endDate}
                            handleEmailIconClick={handleEmailIconClick}
                        />;
                    } else {
                        return "";
                    }
                }
            default:
                return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        }
    };


    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch3 editEmp issueAlign">
                <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
                <div className="mr-12 mv-7 issueicon">
                    <SingleSelect
                        value={clientName}
                        className={`field ${styles.ddminWidth}`}
                        name={"clientName"}
                        label={"Client Name"}
                        onChange={(e) => handleclientNameChange(e)}
                        options={clientNameList}
                    />
                </div>
                <div className="mr-12 mv-7">
                    <TextField
                        name="startDate"
                        value={startDate}
                        fullWidth={true}
                        size="small"
                        placeholder=""
                        label={"Start Date"}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        className={`field ${styles.wdate}`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            max: moment().format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                        }}
                    />
                </div>
                <div className="mr-12 mv-7">
                    <TextField
                        name="endDate"
                        value={endDate}
                        fullWidth={true}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        label={"End Date"}
                        variant="outlined"
                        className={`field ${styles.wdate}`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            min: moment(startDate).format("YYYY-MM-DD"),
                            max: moment().add(1, "day").format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                        }}
                    />
                </div>
                <div className="mr-12 mv-7">
                    <SingleSelect
                        value={empName}
                        className={`field ${styles.ddminWidth}`}
                        name={"TeamMember"}
                        label={"Team Member"}
                        onChange={handleEmpNameChange}
                        options={employeNameList}
                    />
                </div>
            </div>
        );
    };


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Work Site Reports"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Reports" },
                        { label: "Client Reports", to: "/reports/clientreport" }
                    ]}
                    BreadCrumbCurrentPageTitle={"WorkSite Reports"}
                    showLastSeen={true}
                ></PageTitle>


                <div className={`sectionBox`}>
                    {!detailedView && (
                        <TableToolbar
                            rightComponent={tableRightComponent()}
                        />
                    )}
                    <ResponsiveTable
                        rows={clientsList}
                        getColumns={getColumns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        detailedView={detailedView}
                        setDetailedView={setDetailedView}
                        fileName={"Work Site Report"}
                        onClickPdf={handlePdfClick}
                        onClickExcel={handleExcelClick}
                        onClickCsv={handleCSvClick}
                        clientsListCSV={clientsListCSV}
                        onClickEmail={handelEmailClicks}
                    />
                </div>
            {!detailedView && (
                <div className={styles.report}>
                    <div className={styles.download1}>
                        <EmailRoundedIcon />
                        <div>
                            <Link to={clientsList.length !== 0 && "/emailreports"} rel="noopener" >
                                <Button
                                    className={styles.actions}
                                    disabled={clientsList.length === 0}
                                    onClick={() => {
                                        handelEmailFullReport()
                                    }}
                                >
                                    Full Report
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.download1}>
                        <PictureAsPdfIcon />
                        <div>
                            <Button
                                className={styles.actions}
                                disabled={clientsList.length === 0}
                                onClick={() => {
                                    downloadForFullPDF();
                                }}
                            >
                                Download Full Report
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            </Container>
        </>
    );
}

export default WorkSiteReport;