import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
import { BaseUrl, COLLECTIONS, ROLES, EMP_ROLES, DOMAINURL } from "../config";
import { firebase } from "../firebase-config";

import { generateP } from "../../services/helpers";
const defaultHeaders = {
  "Content-Type": "application/json",
  withCredentials: true,
};

/**
 * get list of all employees
 * @param {*} formData  : may be used for filter data by fields
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeesList(formData, onSuccess, onError) {
  firebase
    .firestore()
    .collection(COLLECTIONS.USERS)
    .where("role", "==", ROLES.EMPLOYEE)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
}

/**
 * get list of all employees
 * @param {*} formData  : may be used for array ids
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeesListBasedOnId(formData, onSuccess, onError) {
  let colRef = firebase.firestore().collection(COLLECTIONS.USERS);
  await colRef
    .where(firebase.firestore.FieldPath.documentId(), 'in', formData)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
};

/**
 * Get details of perticular employee
 * @param {*} id : id of employee
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployee(id, onSuccess, onError) {
  try {
    firebase
      .firestore()
      .collection(COLLECTIONS.USERS)
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: "Employee not found" });
        } else {
          let data = doc.data();
          onSuccess &&
            onSuccess({ status: true, data: { id: doc.id, ...data } });
        }
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * update group collection record for employess
 * add or remove employee from perticular group
 * @param {*} type [add or remove]
 * @param {*} groupId
 * @param {*} EmployeeId
 * @returns
 */
export async function updateEmployeetoGroup(type, groupId, EmployeeId) {
  let document = firebase
    .firestore()
    .collection(COLLECTIONS.GROUPS)
    .doc(groupId);
  if (type === "add") {
    return await document
      .set(
        {
          employeeId: firebase.firestore.FieldValue.arrayUnion(EmployeeId),
        },
        { merge: true }
      )
      .then((doc) => {
        return { status: true, message: "Updated successfully" };
      })
      .catch((error) => {
        return { status: false, error: error };
      });
  } else if (type === "remove") {
    return await document
      .set(
        {
          employeeId: firebase.firestore.FieldValue.arrayRemove(EmployeeId),
        },
        { merge: true }
      )
      .then((doc) => {
        return { status: true, message: "Updated successfully" };
      })
      .catch((error) => {
        return { status: false, error: error };
      });
  }
}

/**
 * Create User with employee role in users collection
 * called from addemployee function
 * @param {*} formData
 * @returns
 */
async function createEmployeeUser(formData) {
  let currentUserId = firebase.auth().currentUser.uid;
  return await firebase
    .firestore()
    .collection(COLLECTIONS.USERS)
    .doc(formData?.userId)
    .set({
      ...formData,
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const responseItem = formData;
        responseItem.id = formData?.userId;
        return { status: true, data: responseItem };
      },
      (error) => {
        return { status: false, error: error };
      }
    )
    .catch((e) => {
      return { status: false, error: e };
    });
}

/**
 * Update data of user
 * @param {*} id
 * @param {*} formData
 * @returns
 */
export async function updateUserPassword(id, formData) {
  try {
    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };

    const res = await axiosInstance.post(
      `${BaseUrl}updateUserPass`,
      { email: formData.email, password: formData.password },
      { headers: { ...defaultHeaders, ...authHeaders } }
    );
    return {
      status: true,
      message: "Updated successfully",
      response: formData,
    };
  } catch (Err) {
    return { status: false, error: Err };
  }
}
export async function updateUser(id, formData) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase
    .firestore()
    .collection(COLLECTIONS.USERS)
    .doc(id);

  let userRes = await document
    .set(
      {
        ...formData,
        updatedAt: new Date().getTime(),
        updatedBy: currentUserId,
      },
      { merge: true }
    )
    .then(async (doc) => {
      return {
        status: true,
        message: "Updated successfully",
        response: doc,
      };
    })
    .catch((error) => {
      return { status: false, error: error };
    });
  return userRes;
}

/**
 * Add employee to user
 * First create user to firebase auth user then add employee data to users collection
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addEmployee(formData, onSuccess, onError) {
  try {
    let NewUserData = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
    };

    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
    const res = await axiosInstance
      .post(
        `${BaseUrl}createUser`,
        { ...NewUserData },
        { headers: { ...defaultHeaders, ...authHeaders } }
      )
      .then(async (CreatedUser) => {
        if (CreatedUser?.data?.status === true) {
          if (formData.employeerole === EMP_ROLES.CLIENT_TEAM) {
            await axiosInstance.post(
              `${BaseUrl}sendEmailData`,
              {
                email: NewUserData.email,
                subject: "Your ProTeams account was successfully created",
                contenttxt:
                  `<p>Hello ` +
                  NewUserData.firstname +
                  `,</p>
                  <p></p>
              <p>You've been invited to the ProTeams mobile app. Please click on the
              https://${DOMAINURL}/apps/ to download the app from iOS or Android stores.
              Once you have installed the app you can you login by using the following details:</p>
              <p>User name:<br />` +
                  NewUserData.email +
                  `</p> 
               <p>Password:<br />` +
                  NewUserData.password +
                  `</p>
                  <p></p>
                  <p></p>
                  <p>VBS Support Team</p>
                  `,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  withCredentials: true,
                  ...authHeaders,
                },
              }
            );

            await axiosInstance.post(
              `${BaseUrl}sendSmsText`,
              {
                phoneNumber: [NewUserData.phoneNumber],
                textMessage: `Hello ${NewUserData.firstname},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${NewUserData.email}`,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  withCredentials: true,
                  ...authHeaders,
                },
              }
            );
          } else if (
            formData.employeerole === EMP_ROLES.ADMIN ||
            formData.employeerole === EMP_ROLES.GUEST ||
            formData.employeerole === EMP_ROLES.SUPPORT
          ) {
            await axiosInstance.post(
              `${BaseUrl}sendEmailData`,
              {
                email: NewUserData.email,
                subject: "Your ProTeams account was successfully created",
                contenttxt:
                  `<p>Hello ` +
                  NewUserData.firstname +
                  `,</p>
                  <p></p>
              <p>You have been invited to the ProTeams dashboard. To login and access your account please
              use the following credentials:</p>
              <p>Website: ${DOMAINURL}</p> 
              <p>Email Address: ` +
                  NewUserData.email +
                  `</p> 
               <p>Password :` +
                  NewUserData.password +
                  `</p>
                <p>Role :` +
                  formData.employeerole +
                  `</p>
                  <p></p>
                  <p></p>
                  <p>The VBS Support Team</p>
                  <p></p>
                  <p>*Note this app is best used on a desktop or laptop computer</p>`,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  withCredentials: true,
                  ...authHeaders,
                },
              }
            );

            await axiosInstance.post(
              `${BaseUrl}sendSmsText`,
              {
                phoneNumber: [NewUserData.phoneNumber],
                textMessage: `Hello ${NewUserData.firstname},\n\nYou are invited to the ProTeams Dashboard!\n\nPlease check ${NewUserData.email} for the URL, your ID & password`,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  withCredentials: true,
                  ...authHeaders,
                },
              }
            );
          }
          const newEmployeeUser = {
            role: ROLES.EMPLOYEE,
            userId: CreatedUser?.data?.uid, // userid from firebase auth user
            firstName: formData?.firstName ? formData?.firstName : "",
            lastName: formData.lastName ? formData.lastName : "",
            email: formData.email ? formData.email : "",
            userName: formData.email ? formData.email : "",
            phoneNumber: formData.phoneNumber ? formData.phoneNumber : "",
            clients: formData.clients ? formData.clients : [],
            employeerole: formData.employeerole ? formData.employeerole : "",
            group: formData.group ? formData.group : [],
            manualAcuityId: formData.manualAcuityId
              ? formData.manualAcuityId
              : "",
            acuityId: formData.acuityId ? formData.acuityId : "",
            status: true,
          };
          let create_user_res = await createEmployeeUser(newEmployeeUser);
          if (create_user_res?.status === true) {
            onSuccess(create_user_res);
          } else {
            onError(create_user_res);
          }
        }
      });
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Update Employee user data
 * @param {*} id : employee id
 * @param {*} formData : only pass data to be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateEmployee(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  delete formData.employeeId;
  delete formData.id;
  let employeeData = {};
  let password = generateP();
  try {
    const doc = await firebase
      .firestore()
      .collection(COLLECTIONS.USERS)
      .doc(id)
      .get();
    employeeData = doc.data();
  } catch (e) {
    console.log("errr>>", e);
  }
  let NewUserData = {
    firstname: formData.firstName,
    lastname: formData.lastName,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    password: password,
    isUpdate: true,
  };
  if (formData.userName !== formData.email) {
    formData.userName = formData.email;

    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
    const res = await axiosInstance.post(
      `${BaseUrl}createUser`,
      { ...NewUserData },
      { headers: { ...defaultHeaders, ...authHeaders } }
    );
    console.log(res);
  }

  await firebase
    .firestore()
    .collection(COLLECTIONS.USERS)
    .doc(id)
    .set(
      {
        ...formData,
        updatedAt: new Date().getTime(),
        updatedBy: currentUserId,
      },
      { merge: true }
    )
    .then(async (doc) => {
      const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
      if (employeeData.employeerole !== formData.employeerole) {
        const res = await axiosInstance.post(
          `${BaseUrl}updateUserPass`,
          { email: NewUserData.email, password: NewUserData.password },
          { headers: { ...defaultHeaders, ...authHeaders } }
        );

        if (formData.employeerole === EMP_ROLES.CLIENT_TEAM) {
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: NewUserData.email,
              subject: "Your ProTeams account was successfully created",
              contenttxt:
                `<p>Hello ` +
                NewUserData.firstName +
                `,</p>
                <p></p>
            <p>You've been invited to the ProTeams mobile app. Please click on the
            https://${DOMAINURL}/apps/ to download the app from iOS or Android stores.
            Once you have installed the app you can you login by using the following details:</p>
            <p>User name:<br />` +
                NewUserData.email +
                `</p> 
             <p>Password:<br />` +
                password +
                `</p>
                <p></p>
                <p></p>
                <p>VBS Support Team</p>
                `,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: [NewUserData.phoneNumber],
              textMessage: `Hello ${NewUserData.firstname},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${NewUserData.email}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        } else if (
          formData.employeerole === EMP_ROLES.ADMIN ||
          formData.employeerole === EMP_ROLES.GUEST ||
          formData.employeerole === EMP_ROLES.SUPPORT
        ) {
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: NewUserData.email,
              subject: "Your ProTeams account was successfully created",
              contenttxt:
                `<p>Hello ` +
                NewUserData.firstName +
                `,</p>
                <p></p>
            <p>You have been invited to the ProTeams dashboard. To login and access your account please
            use the following credentials:</p>
            <p>Website: ${DOMAINURL}</p> 
            <p>Email Address: ` +
                NewUserData.email +
                `</p> 
             <p>Password :` +
                password +
                `</p>
              <p>Role :` +
                formData.employeerole +
                `</p>
                <p></p>
                <p></p>
                <p>The VBS Support Team</p>
                <p></p>
                <p>*Note this app is best used on a desktop or laptop computer</p>`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: [NewUserData.phoneNumber],
              textMessage: `Hello ${NewUserData.firstname},\n\nYou are invited to the ProTeams Dashboard!\n\nPlease check ${NewUserData.email} for the URL, your ID & password`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
      }

      onSuccess &&
        onSuccess({
          status: true,
          message: "Updated successfully",
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: "Not Found" });
      }
      onError && onError({ status: false, error: error.code });
    });
}

/**
 * Remove Employee user from system
 * First remove from firebase auth
 * then remove from users collection
 * @param {*} id
 * @param {*} empData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteEmployee(id, empData, onSuccess, onError) {
  if (id && id !== "") {
    const document = firebase.firestore().collection(COLLECTIONS.USERS).doc(id);
    document.get().then(async (doc) => {
      if (!doc.exists) {
        onError && onError({ status: false, error: "Document Not found" });
      } else {
        let data = doc.data();
        let toRemove = data.group;
        toRemove.map(async (groupItem) => {
          await updateEmployeetoGroup("remove", groupItem, id);
          return groupItem;
        });
        await document.delete();

        const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
        const res = await axiosInstance
          .post(
            `${BaseUrl}removeUser`,
            { id: id },
            { headers: { ...defaultHeaders, ...authHeaders } }
          )
          .then(async (CreatedUser) => {})
          .catch((error) => {
            console.log("error while detele user", error);
          });

        onSuccess &&
          onSuccess({ status: true, message: "Delete successfull", res });
        const emailtext = `<p>Hello,</p> 
        <p>A user with an email address ${empData.email} has been deleted from the CSM app.
        Please contact management if this was an error. Thank you,</p>

        <p></p>
        <p>VBS Support Team</p>`;
        await axiosInstance.post(
          `${BaseUrl}sendEmailData`,
          {
            email: "support@procleanings.com",
            subject: `A user was removed from the Proteams App`,
            contenttxt: emailtext,
          },
          {
            headers: {
              "Content-Type": "application/json",
              withCredentials: true,
              ...authHeaders,
            },
          }
        );
      }
    });
  } else {
    onError({ status: false, message: "Invalid input" });
  }
}

/**
 * Returns summary data for employee collection
 * @param {*} formData : may be used for filter by different filed
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeeSummary(formData, onSuccess, onError) {
  firebase
    .firestore()
    .collection(COLLECTIONS.USERS)
    .where("role", "==", ROLES.EMPLOYEE)
    .limit(4)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
}

/**
 * Returns summary data for employee collection
 * @param {*} formData : may be used for filter by different filed
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeeByFilter(formData, onSuccess, onError) {
  if (formData.clients || formData.groups) {
    // console.log("formdata", formData);

    let refusers = firebase
      .firestore()
      .collection(COLLECTIONS.USERS)
      .where("role", "==", ROLES.EMPLOYEE);

    // if (formData.clients && formData.clients !== 'All') {
    //   refusers = refusers.where(
    //     'clients',
    //     'array-contains',
    //     formData?.clients?.id
    //   );
    // }

    // if (formData.groups && formData.groups !== 'All') {
    //   refusers = refusers.where(
    //     'group',
    //     'array-contains',
    //     formData?.groups?.groupId
    //   );
    // }
    refusers
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });

        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        onError && onError(error);
      });
  }
}

export const setUserInformation = async (userId, data) => {
  return firebase.default
    .firestore()
    .collection("users")
    .doc(userId)
    .update(data);
};
