import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Popover,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import moment from "moment-timezone";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../../components/Table/ThemeTable";
import TableToolbar from "../../../../components/Table/TableToolbar";
import {
  getTimesheetListReport,
  onTimeUpdateTimesheetList,
} from "../../../../services/apiservices/timesheets";
import { getUserListBasedOnIds } from "../../../../services/apiservices/users";
import Link from "@mui/material/Link";
import styles from "../../Report.module.scss";
import "../../../home/dashboard.module.scss";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import { CSVLink, CSVDownload } from "react-csv";
import * as Excel from "exceljs";
import { useNavigate } from "react-router-dom";
import ResponsiveTable from "../../../../components/ReportsComponent/EmployeeReports/ResponsiveTimeSheet";

function formatHoursMinutes(num) {
  return num.toFixed(2);
  const n = new Date(0, 0);
  n.setMinutes(+Math.round(num * 60));

  let hours = n.getHours()
  const minutes = n.getMinutes();

  if (num) {
    const hrArr = num.toString().split(".");
    hours = hrArr[0];
  }
  return (hours + ":" + minutes.toString().padStart(2, '0'));
}

const CustomTooltip = ({
  title,
  listData,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {listData ? (
        <>
          <div onClick={handleClick}>{formatHoursMinutes(title)}</div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="tooltip-heading2 p-5 ">
              <div className="tooltip-container2">
                {listData.map((item, i) => {
                  return (
                    <>
                      <div key={item.shiftName} className="pt-5">
                        <b>Shift : </b> {item.shiftName}
                        <div>
                          <div>
                            <b>In : </b>
                            <Link
                              className={styles.timeZone2}
                              target="_blank"
                              rel="noopener"
                              href={`https://maps.google.com/?q=${item.location?.lat},${item.location?.lng}`}
                            >
                              {item.In}
                            </Link>
                          </div>
                          <div>
                            <b>Out : </b>
                            <Link
                              className={styles.timeZone2}
                              target="_blank"
                              rel="noopener"
                              href={`https://maps.google.com/?q=${item.shiftend_location?.lat},${item.shiftend_location?.lng}`}
                            >
                              {item.Out}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Popover>
        </>
      ) : (
        <div>{formatHoursMinutes(title)}</div>
      )}
    </div>
  );
};

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div className={styles.Action2}>
        <Link to={"/emailreports"} rel="noopener" className={styles.Action2}>
          <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
        </Link>
        <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
      </div>
      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          // vertical: 'left',
          horizontal: "left",
        }}
        transformOrigin={{
          // vertical: 'left',
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",

            // padding: '50px', // Add some padding
          },
        }}
      >
        <div className={styles.box}>
          <div className={styles.box2}>Download</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              left: 20,
            }}
          >
            <div>
              <CSVLink
                data={clientsListCSV}
                filename={`TimeSheetReport_${rcellItem?.firstName}${rcellItem.lastName}_${startDate}_${endDate}.csv`}
                className={styles.Link}
                asyncOnClick={true}
                onClick={(event, done) => {
                  setDownloadCSVData(event, done, rcellItem);
                }}
              >
                csv
              </CSVLink>
            </div>
            <div>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  downloadExcel(rcellItem);
                }}
              >
                <u className={styles.Link}>Excel</u>
              </a>
            </div>
            <div>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  downloadPDF(rcellItem);
                }}
              >
                <u className={styles.Link}>Pdf</u>
              </a>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

function TimeSheet(props) {
  const [clientsListCSV, setClientListCSV] = useState([]);
  const navigate = useNavigate();
  const [payPeriod, setPayPeriod] = useState("None");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [empName, setEmpName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [empFilterData, setEmpFilterData] = useState([]);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:768px)");
  const isLandscape = useMediaQuery(
    "(min-width: 771px) and (max-width: 940px)"
  );
  const [detailedView, setDetailedView] = useState(null);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let paramStartDate = "";
    let paramEndDate = "";
    if (payPeriod !== "None") {
      paramStartDate = moment().subtract(payPeriod, "weeks").startOf("isoWeek");
      paramEndDate = moment().subtract(payPeriod, "weeks").endOf("isoWeek");
    } else {
      paramStartDate = startDate;
      paramEndDate = endDate;
    }
    getReportListData(paramStartDate, paramEndDate);
    // getTimeSheetsData();
  }, [payPeriod, startDate, endDate]);

  const getTimeSheetsData = () => {
    onTimeUpdateTimesheetList(
      {},
      (res) => {
        // console.log("Res,", res);
      },
      (err) => {
        console.log("err,", err);
      }
    );
  };
  const getPayPeriodDropDownOptions = () => {
    const weeksDate = [];
    weeksDate.push({ label: "None", value: "None" });
    for (let i = 1; i <= 4; i++) {
      const startStr = moment()
        .subtract(i, "weeks")
        .startOf("isoWeek")
        .format("ddd MMM DD, YYYY");
      const endStr = moment()
        .subtract(i, "weeks")
        .endOf("isoWeek")
        .format("ddd MMM DD, YYYY");
      weeksDate.push({ label: `${startStr} - ${endStr}`, value: i });
    }
    return weeksDate;
  };
  const onChangePayPeriod = (e) => {
    setPayPeriod(e.target.value);
  };

  const handleEmpNameChange = (e) => {
    const str = e.target.value;
    setEmpName(str);

    const empfinalData = empData.filter((itme) =>
      itme.name.toUpperCase().includes(str?.toUpperCase())
    );
    setEmpFilterData(empfinalData);
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Employee Name",
      isSortable: true,
    });

    let paramStartDate = "";
    let paramEndDate = "";
    if (payPeriod !== "None") {
      paramStartDate = moment().subtract(payPeriod, "weeks").startOf("isoWeek");
      paramEndDate = moment().subtract(payPeriod, "weeks").endOf("isoWeek");

      for (
        let m = moment(paramStartDate);
        m.isBefore(paramEndDate);
        m.add(1, "days")
      ) {
        columns.push({
          id: m.format("YYYY-MM-DD"),
          numeric: false,
          disablePadding: false,
          label: isMobile || isLandscape ? m.format("MMM DD YYYY") : m.format("MM/DD (ddd)"),
          isSortable: true,
          clickable: true,
        });
      }
    } else if (startDate !== "" && endDate !== "") {
      paramStartDate = startDate;
      paramEndDate = endDate;
      for (
        let m = moment(startDate);
        m.isSameOrBefore(endDate);
        m.add(1, "days")
      ) {
        columns.push({
          id: m.format("YYYY-MM-DD"),
          numeric: false,
          disablePadding: false,
          label: isMobile || isLandscape ? m.format("MMM DD YYYY") : m.format("MM/DD (ddd)"),
          isSortable: true,
          clickable: true,
        });
      }
    }

    columns.push({
      id: "total",
      numeric: false,
      disablePadding: false,
      label: "Total",
      isSortable: true,
    });

    columns.push({
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      isSortable: false,
    });
    if (isMobile || isLandscape) {
      return columns.filter(col => col.id !== "action");
    }
    return columns;
  };

  const getDatatoDownload = (rcellItem, columns, date) => {
    if (date !== undefined) {
      const clientCSV = rcellItem.map((item) => {
        const baseRow = [
          `${item?.firstName} ${item.lastName}`,
        ];

        let cellValue;
        columns.forEach((col) => {
          if (col.id == date) {
            cellValue = item[col.id];
            baseRow.push(cellValue !== undefined ? cellValue : "-");
          }
        });
        item.total = cellValue;
        baseRow.push(item.total);

        const popupDataValue = item.popupData && item.popupData[date] ? item.popupData[date] : null;
        const popupDataRow = popupDataValue ? popupDataValue.map(data => `Shift:${data.shiftName}\nIn:${data.In}\nOut:${data.Out}`).join('\n\n')
          : "-";

        return popupDataRow ? [baseRow, ['', popupDataRow]] : [baseRow];
      });

      return clientCSV.flat();

    } else {
      if (Array.isArray(rcellItem)) {
        const newRecords = [];

        rcellItem.forEach((obj) => {
          if (!newRecords.some(item => item.name === obj.name)) {
            newRecords.push(obj);
          }
        });

        const clientCSV = newRecords.flatMap((item) => {
          const baseRow = [
            `${item?.firstName} ${item.lastName}`,
          ];

          columns.forEach((col) => {
            if (col.id !== "name" && col.id !== "total" && col.id !== "action") {
              const cellValue = item[col.id];
              baseRow.push(cellValue !== undefined ? cellValue : "-");
            }
          });

          baseRow.push(item?.total !== undefined ? item.total : "-");

          const dataRows = columns.map(col => {
            const date = col.id;
            if (item.popupData && item.popupData[date]) {
              const popupForDate = item.popupData[date];
              if (popupForDate.length > 0) {
                const shiftDetails = popupForDate.map(data => `Shift:${data.shiftName}\nIn:${data.In}\nOut:${data.Out}`).join('\n\n');
                return shiftDetails;
              }
              return "-";
            }
            return "-";
          });

          return [baseRow, dataRows];
        });
        return clientCSV;
      } else {
        const clientCSV = empData
          .filter((item) => item.userId === rcellItem.userId)
          .flatMap((item) => {
            const baseRow = [
              `${item?.firstName} ${item.lastName}`,
            ];

            columns.forEach((col) => {
              if (col.id !== "name" && col.id !== "total" && col.id !== "action") {
                const cellValue = item[col.id];
                baseRow.push(cellValue !== undefined ? cellValue : "-");
              }
            });

            baseRow.push(item?.total !== undefined ? item.total : "-");

            const dataRows = columns.map(col => {
              const date = col.id;
              if (rcellItem.popupData[date]) {
                const popupForDate = rcellItem.popupData[date];
                if (popupForDate.length > 0) {
                  const shiftDetails = popupForDate.map(data => `Shift:${data.shiftName}\nIn:${data.In}\nOut:${data.Out}`).join('\n\n');
                  return shiftDetails;
                }
                return "-";
              }
              return "-";
            });

            return [baseRow, dataRows];
          });
        return clientCSV;
      }
    }
  };


  const getDatatoFullDownload = (items, columns) => {
    if (!Array.isArray(items)) {
      items = [items];
    }

    const dataRows = items.flatMap(item => {
      const baseRow = [
        `${item?.firstName} ${item?.lastName}`,
      ];

      columns.forEach(col => {
        if (col.id !== "name" && col.id !== "action") {
          const cellValue = item[col.id];
          baseRow.push(cellValue != undefined ? cellValue : "-");
        }
      });

      const datewiseData = columns.map(col => {
        const date = col.id;
        if (item.popupData && item.popupData[date]) {
          const popupForDate = item.popupData[date];
          const shiftDetails = popupForDate.map(data => `Shift:${data.shiftName}\n In:${data.In}\n Out:${data.Out}`).join('\n\n');
          return shiftDetails;
        }
        return "-";
      });

      return [
        baseRow,
        datewiseData,
      ];
    });

    return dataRows;
  };

  const selectedPayPeriodOption = getPayPeriodDropDownOptions().find(option => option.value === payPeriod);

  const downloadForExcel = async (rcellItem, date, emailSend) => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet("TimeSheet Report");

    const columns = getColumns(payPeriod, startDate, endDate);
    const clientCSV = getDatatoDownload(rcellItem, columns, date);
    const csvHeaders = date ? columns.filter(col => col.id === "name" || col.id === date || col.id === "total").map(col => col.label) : columns.filter(col => col.id !== "action").map(col => col.label);
    const dates = payPeriod !== "None" ? `${selectedPayPeriodOption.label}` : (isMobile || isLandscape) ? `${date}` : `${startDate}_${endDate}`;

    let fileName;
    if (Array.isArray(rcellItem)) {
      if (rcellItem.length === 1) {
        fileName = `TimeSheetReport_${rcellItem[0].employeeName}_${dates}`;
      } else {
        fileName = `TimeSheetReport_All_${dates}`;
      }
    } else {
      fileName = `TimeSheetReport_${rcellItem.employeeName}_${dates}`;
    }
    let name = true;
    let fullpage = true;
    return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name, fullpage);
  }

  const downloadForPDF = async (rcellItem, date, emailSend) => {
    const columns = getColumns(payPeriod, startDate, endDate);
    const clientCSV = getDatatoDownload(rcellItem, columns, date);

    const csvHeaders = date ? columns.filter(col => col.id == "name" || col.id == date || col.id == "total").map(col => col.label) : columns.filter(col => col.id !== "action").map(col => col.label);

    const dates = payPeriod !== "None" ? `${selectedPayPeriodOption.label}` : (isMobile || isLandscape) ? `${date}` : `${startDate}_${endDate}`;

    let fileName;
    if (Array.isArray(rcellItem)) {
      if (rcellItem.length === 1) {
        fileName = `TimeSheetReport_${rcellItem[0].employeeName}_${dates}`;
      } else {
        fileName = `TimeSheetReport_All_${dates}`;
      }
    } else {
      fileName = `TimeSheetReport_${rcellItem.employeeName}_${dates}`;
    }
    let name = true;
    let fullpage = true;
    return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name, fullpage);
  }

  const setDownloadCSVData = (event, done, rcellItem, date) => {
    const columns = getColumns(payPeriod, startDate, endDate);
    let csvHeaders;

    if (date) {
      csvHeaders = columns
        .filter(col => col.id === "name" || col.id === date || col.id === "total")
        .map(col => col.label);
    } else {
      csvHeaders = columns
        .filter(col => col.id !== "action")
        .map(col => col.label);
    }
  
  
    console.log("csvHeaders2", csvHeaders);

    console.log("csvHeaders2", csvHeaders);

    const clientCSV = getDatatoDownload(rcellItem, columns, date);

    const reportName = "TimeSheet Report";
    const employeeName = Array.isArray(rcellItem) ? (rcellItem.length === 1 ? rcellItem[0].employeeName : 'All') : rcellItem.employeeName;

    const reportPeriod = payPeriod !== "None"
      ? selectedPayPeriodOption.label
      : (isMobile || isLandscape)
        ? date
        : `${startDate}_${endDate}`;

    // Create the report details rows
    const reportDetails = [
      [`Employee Name: ${employeeName}`],
      [`Report Name: ${reportName}`],
      [`Report Period: ${reportPeriod}`],
      [],
      [...csvHeaders],
      ...clientCSV,
    ];

    // Set the CSV data
    setClientListCSV(reportDetails);
    done();
  };

  const downloadForFullPDF = async (emailSend) => {
    const columns = getColumns(payPeriod, startDate, endDate);
    const csvHeaders = columns.filter(col => col.id !== "action").map(col => col.label);
    const clientCSV = getDatatoFullDownload(empData, columns);

    let empNamefordwonLoad = empData.length > 1 ? "All" : `${empData[0]?.firstName}${empData[0]?.lastName}`;
    let fileName = `TimeSheetReport_${empNamefordwonLoad}_${payPeriod !== "None" ? selectedPayPeriodOption.label : `${startDate}_${endDate}`}`;
    let name = true;
    let fullpage = true;
    return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name, fullpage);
  }

  const downloadForFullExcel = async (emailSend) => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet("TimeSheet Report");

    const columns = getColumns(payPeriod, startDate, endDate);
    const csvHeaders = columns.filter(col => col.id !== "action").map(col => col.label);
    const clientCSV = getDatatoFullDownload(empData, columns);

    let empNamefordwonLoad = empData ? `${empData?.firstName} ${empData.lastName}` : "All";
    const names = payPeriod !== "None" ? `${selectedPayPeriodOption.label}` : `${startDate}_${endDate}`;
    let fileName = `TimeSheetReport_${empNamefordwonLoad}_${names}`;
    let name = true;
    let fullpage = true;
    return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name, fullpage);
  }

  const handleEmailIconClick = async (rcellItem, date) => {
    const emailSend = true;
    const excelBlob = await downloadForExcel(rcellItem, date, emailSend);
    let name;
    if (Array.isArray(rcellItem)) {
      if (rcellItem.length === 1) {
        name = rcellItem[0]?.employeeName;
      } else {
        name = 'All';
      }
    } else {
      name = rcellItem?.employeeName;
    }

    const Dates =  payPeriod !== "None" && (isMobile || isLandscape) ? `${selectedPayPeriodOption.label}` : payPeriod === "None" && (isMobile || isLandscape) ? `${date}` : `${startDate}`;
    localStorage.setItem("FileName", "TimeSheet Report");

    localStorage.setItem("ReportsName", "Employee Report");

    localStorage.setItem("Name", name);

    localStorage.setItem("Date", Dates);

    localStorage.setItem("EndDate", endDate);

    const pdfBlob = await downloadForPDF(rcellItem, date, emailSend);
    localStorage.setItem("pdfData", pdfBlob);
    setIsLoading(true);

    // Convert the Excel blob to a base64-encoded string
    const reader = new FileReader();
    reader.readAsDataURL(excelBlob);
    reader.onloadend = function () {
      const base64Data = reader.result;
      localStorage.setItem("emailData", base64Data);

      // Navigate to the email reports page
      navigate("/emailreports");
    };
  };

  const handelEmailFullReport = async () => {
    const emailSend = true;
    localStorage.setItem("FileName", "TimeSheet Report");

    localStorage.setItem("ReportsName", "Employee Report");

    let empNamefordwonLoad = empData.length > 1 ? "All" : `${empData[0]?.firstName}${empData[0]?.lastName}`;

    const Dates =  payPeriod !== "None" ? `${selectedPayPeriodOption.label}` : `${startDate}`;

    localStorage.setItem("Name", empNamefordwonLoad);

    localStorage.setItem("Date", Dates);

    localStorage.setItem("EndDate", endDate);

    setIsLoading(true);
    const fullReport = await downloadForFullPDF(emailSend);
    localStorage.setItem("fullReport", fullReport);

    const fullExcelReport = await downloadForFullExcel(emailSend);
    const reader = new FileReader();
    reader.readAsDataURL(fullExcelReport);
    reader.onloadend = function () {
      const base64Data = reader.result;
      localStorage.setItem("fullExcelReport", base64Data);

      navigate("/emailreports");
    };
  };

  const handlePdfClick = async (records, date, emailSend) => {
    await downloadForPDF(records, date, emailSend);
  }

  const handleExcelClick = async (records, date, emailSend) => {
    await downloadForExcel(records, date, emailSend);
  }

  const handleCSvClick = async (event, done, records, date) => {
    await setDownloadCSVData(event, done, records, date);
  };

  const handelEmailClicks = async (records, date) => {
    await handleEmailIconClick(records, date);
  }

  function formatTime(timestamp, timezone="America/New_York") {
    const seconds = timestamp?.seconds;
    const milliseconds = seconds * 1000;
    return moment(milliseconds).tz(timezone).format("hh:mm A");
  }
  // Function to format time difference
  function formatTimeDifference(hours, minutes) {
    if (hours === 0 && minutes === 0) {
      return "0";
    } else {
      const absHours = Math.abs(hours);
      const absMinutes = parseInt(Math.abs(minutes*100/60), 10);
      return `${absHours}.${absMinutes.toString().padStart(2, "0")}`;
    }
  }

  const mapWidhtUserList = async (userIds, timeSheetData) => {
    getUserListBasedOnIds(
      userIds,
      (result) => {
        const finaleData = result.map((resItem) => {
          const userTimeSheet = timeSheetData.filter(
            (itm) => itm.userId === resItem.id
          );
          console.log(userTimeSheet);

          let totalHrs = 0;
          let popupData = {};
          let dateArr = [];
          let allCheckInMiliseconds = [];
          let hours = 0;

          for (let i = 0; i < userTimeSheet?.length; i++) {
            const checkInTime = formatTime(userTimeSheet[i]?.checkInDateTime, userTimeSheet[i]?.calendarTimezone);
            const checkOutTime = formatTime(userTimeSheet[i]?.checkOutDateTime, userTimeSheet[i]?.calendarTimezone);

            const checkInOutTimeDiff = moment.duration(
              moment
                .unix(userTimeSheet[i]?.checkOutDateTime?.seconds)
                .diff(moment.unix(userTimeSheet[i]?.checkInDateTime?.seconds))
            );

            const checkInStartTimeDiffHours = !checkInOutTimeDiff?.hours()
              ? 0
              : checkInOutTimeDiff?.hours();
            const checkInStartTimeDiffMinutes = !checkInOutTimeDiff?.minutes()
              ? 0
              : checkInOutTimeDiff?.minutes();

            hours = formatTimeDifference(
              checkInStartTimeDiffHours,
              checkInStartTimeDiffMinutes
            );

            let checkInMiliseconds = parseFloat(
              checkInOutTimeDiff?._milliseconds
            );
            if (checkInMiliseconds < 0) {
              checkInMiliseconds = -1 * checkInMiliseconds;
            }
            allCheckInMiliseconds.push(checkInMiliseconds);

            const totalCheckInMilliseconds = allCheckInMiliseconds.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const totalHours = Math.floor(
              totalCheckInMilliseconds / (1000 * 60 * 60)
            );
            const totalMinutes = Math.floor(
              (totalCheckInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
            );

            totalHrs = `${totalHours}.${totalMinutes}`;

            const taskArr = Object.keys(userTimeSheet[i].tasks);
            const shiftName = userTimeSheet[i].tasks[taskArr[0]].title;

            const popupItem = {
              location: userTimeSheet[i].location,
              shiftend_location: userTimeSheet[i].shiftend_location,
              shiftName: shiftName,
              In: checkInTime,
              Out:
                checkOutTime === "Invalid date"
                  ? "Not Available"
                  : checkOutTime,
            };

            // Extracting appointment date
            const appointmentDate = moment
              .tz(userTimeSheet[i]["appointmentDateTime"], "America/New_York")
              .format("YYYY-MM-DD");
            if (popupData[appointmentDate]) {
              popupData[appointmentDate].push(popupItem);
            } else {
              popupData[appointmentDate] = [popupItem];
            }

            if (
              resItem[
              moment(userTimeSheet[i]["appointmentDate"]).format("YYYY-MM-DD")
              ]
            ) {
              // let tmpTotal =  (resItem[
              //   moment(userTimeSheet[i]["appointmentDate"]).format("YYYY-MM-DD")
              // ] + parseFloat(hours)).toFixed(2);

              // const tmpTotalArr = tmpTotal.toString().split(".");

              // if(tmpTotalArr.length > 0) {
              //     let decimalAmt = tmpTotalArr[1];
              //     if(decimalAmt-60 > 0) {
              //       tmpTotal = parseInt(tmpTotalArr[0], 10) + 1;
              //       if((decimalAmt-60) > 9) {
              //         decimalAmt = (decimalAmt-60)/100;
              //       } else {
              //         decimalAmt = (decimalAmt-60)/10;
              //       }

              //       tmpTotal += decimalAmt;
              //     }

              // }
              // // console.log(tmpTotal);

              resItem[
                moment(userTimeSheet[i]["appointmentDate"]).format("YYYY-MM-DD")
              ] += parseFloat(hours);

              resItem["total"] += parseFloat(hours);
            } else {
              resItem[
                moment(userTimeSheet[i]["appointmentDate"]).format("YYYY-MM-DD")
              ] = parseFloat(hours);
              if (resItem["total"]) {
                resItem["total"] += parseFloat(hours);
              } else {
                resItem["total"] = parseFloat(hours);
              }

              dateArr.push(
                moment(userTimeSheet[i]["appointmentDate"]).format("YYYY-MM-DD")
              );
            }
          }
          for (let key in dateArr) {
            resItem[dateArr[key]] = resItem[dateArr[key]];
          }

          // resItem["total"] = totalHrs;
          resItem["name"] = `${resItem.firstName} ${resItem.lastName}`;
          resItem["popupData"] = popupData;

          return resItem;
        });

        setEmpData(finaleData);
        setEmpFilterData(finaleData);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getReportListData = async (startDt, endDt) => {
    const param = {
      startDate: `${moment(startDt).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${moment(endDt).format("YYYY-MM-DD")}T23:59:59`,
    };
    setIsLoading(true);
    getTimesheetListReport(
      param,
      (result) => {
        setIsLoading(false);
        const empIds = [];
        for (let i = 0; i < result.length; i++) {
          if (empIds.indexOf(result[i].userId) === -1) {
            empIds.push(result[i].userId);
          }
        }
        mapWidhtUserList(empIds, result);
      },
      (err) => {
        setIsLoading(false);
        console.log("err>>", err);
      }
    );
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex groupsearch3 editEmp issueAlign">
        <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> <span> Filters </span> </div>
        <div className="mr-12 mv-7 issueicon" style={{ minWidth: 310 }}>
          <SingleSelect
            value={payPeriod}
            className={`field ${styles.wdate}`}
            name={"payPeriod"}
            label={"Pay Period"}
            onChange={(e) => onChangePayPeriod(e)}
            options={getPayPeriodDropDownOptions()}
          />
        </div>
        <div className="mr-12 mv-7">
          <TextField
            name="startDate"
            value={startDate}
            fullWidth={true}
            size="small"
            placeholder=""
            label={"Start Date"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            className={`field ${styles.wdate}`}
            type={"date"}
            inputProps={{
              className: "p-10",
              max: moment().format("YYYY-MM-DD"),
            }}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="mr-12 mv-7">
          <TextField
            name="endDate"
            value={endDate}
            fullWidth={true}
            size="small"
            InputLabelProps={{ shrink: true }}
            label={"End Date"}
            variant="outlined"
            className={`field ${styles.wdate}`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment(startDate).format("YYYY-MM-DD"),
              max: moment().add(1, "day").format("YYYY-MM-DD"),
            }}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
        <div className="mr-12 mv-7">
          <TextField
            name="search"
            value={empName}
            fullWidth={true}
            size="small"
            label={"Employee"}
            variant="outlined"
            className={`field ${styles.ddminWidth}`}
            type={"text"}
            inputProps={{
              className: "p-10",
            }}
            onChange={handleEmpNameChange}
          />
        </div>
      </div>
    );
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem?.id) {
      case "name":
        return <div>{rcellItem[hcellItem?.id]}</div>;
      case "action":
        if (isMobile || isLandscape) {
          if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return null;
          }
        } else {
          if (
            rowindex !== 0 &&
            empFilterData[rowindex - 1].userId !== rcellItem.userId
          ) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return "";
          }
        }
      default:
        if (!rcellItem[hcellItem?.id]) {
          return "-";
        } else {
          return (
            <CustomTooltip
              listData={rcellItem?.popupData?.[hcellItem.id]}
              title={rcellItem[hcellItem?.id]}
            />
          );
        }
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="TimeSheet Reports"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Reports" },
            { label: "Employee Reports", to: "/reports/employeereport" },
          ]}
          BreadCrumbCurrentPageTitle={"TimeSheet Reports"}
          showLastSeen={true}
        />

        <div className={`sectionBox`}>
          {!detailedView && (
            <TableToolbar
              rightComponent={tableRightComponent()}
            />
          )}
          <div className={`themeTableContainer`}>
            <ResponsiveTable
              rows={empFilterData}
              getColumns={getColumns}
              hidePagination={true}
              isLoading={isLoading}
              renderRowCell={renderRowCell}
              detailedView={detailedView}
              setDetailedView={setDetailedView}
              fileName={"TimeSheet Report"}
              onClickPdf={handlePdfClick}
              onClickExcel={handleExcelClick}
              onClickCsv={handleCSvClick}
              clientsListCSV={clientsListCSV}
              onClickEmail={handelEmailClicks}
              payPeriod={payPeriod}
              getPayPeriodDropDownOptions={getPayPeriodDropDownOptions()}
            />
          </div>
        </div>
        {!detailedView && (
          <div className={styles.report}>
            <div className={styles.download1}>
              <EmailRoundedIcon />
              <div>
                <Link to={empData.length !== 0 && "/emailreports"} rel="noopener">
                  <Button
                    className={styles.actions}
                    disabled={empData.length === 0}
                    onClick={() => {
                      handelEmailFullReport()
                    }}
                  >
                    Full Report
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.download1}>
              <PictureAsPdfIcon />
              <div>
                <Button
                  className={styles.actions}
                  disabled={empData.length === 0}
                  onClick={() => {
                    downloadForFullPDF();
                  }}
                >
                  Download Full Report
                </Button>
              </div>
            </div>
          </div>
        )}
      </Container >
    </>
  );
}

export default TimeSheet;
