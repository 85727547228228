import React, { useContext, useEffect, useState } from "react";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { Container, Button, TextField } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import TableToolbar from "../../../../components/Table/TableToolbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import moment from "moment-timezone";
import styles from "../../Report.module.scss"
import { CSVLink, CSVDownload } from "react-csv";
import { getTimesheetListReport } from "../../../../services/apiservices/timesheets";
import { getUserListBasedOnIds } from "../../../../services/apiservices/users";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as Excel from 'exceljs';
import Popover from "@mui/material/Popover";
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import MultiSelectem from "../../../../components/SelectField/MultiSelectem";
import { getUserActivity } from "../../../../services/apiservices/activity";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveTable from "../../../../components/ReportsComponent/EmployeeReports/ResponsiveEmpTable";
import { useMediaQuery } from "@mui/material";

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    return (
        <>
            <div className={styles.Action}>
                <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
                    <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
                </Link>
                <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
            </div>
            <Popover
                id="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    // vertical: 'left',
                    horizontal: "left",
                }}
                transformOrigin={{
                    // vertical: 'left',
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",

                        // padding: '50px', // Add some padding
                    },
                }}
            >
                <div className={styles.box}>
                    <div className={styles.box2}>Download</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            left: 20,
                        }}
                    >
                        <div>
                            <CSVLink
                                data={clientsListCSV}
                                filename={`ActivityReport_${rcellItem.employeeName}_${startDate}_${endDate}.csv`}
                                className={styles.Link}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    setDownloadCSVData(event, done, rcellItem)
                                }}
                            >
                                csv
                            </CSVLink>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadExcel(rcellItem) }}>
                                <u className={styles.Link}>Excel</u>
                            </a>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadPDF(rcellItem) }}>
                                <u className={styles.Link}>Pdf</u>
                            </a>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};


function ActivityReport(props) {

    const csvHeaders = [
        "Employee Name",
        "Date",
        "Version Of the App(Device)",
        "Action"
    ];

    const [employeeList, setEmployeeList] = useState([]);
    const [employeeListMaster, setEmployeeListMaster] = useState([]);
    const [startDate, setStartDate] = useState();
    const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [empName, setEmpName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empNameList, setEmpNameList] = useState([]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [detailedView, setDetailedView] = useState(null);

    const getColumns = () => {
        const columns = [
            {
                id: "employeeName",
                numeric: false,
                disablePadding: false,
                label: "Name",
                isSortable: false,
            },
            {
                id: "date",
                numeric: false,
                disablePadding: false,
                label: "Date",
                isSortable: false,
            },
            {
                id: "version",
                numeric: false,
                disablePadding: false,
                label: "Version Of the App(Device)",
                isSortable: false,
            },
            {
                id: "actions",
                numeric: false,
                disablePadding: false,
                label: "Action",
                isSortable: false,
            },
            {
                id: "Action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSortable: false,
            },
        ];

        if (isMobile || isLandscape) {
            return columns.filter(col => col.id !== "employeeName" && col.id !== "date" && col.id !== "Action");
        }
        return columns;
    };


    const getDatatoDownload = (rcellItem) => {
        let clientCSV = [];
        if (!Array.isArray(rcellItem)) {
            clientCSV = employeeList?.filter(
                (item) => item.employeNameId === rcellItem.employeNameId
            );
            clientCSV = clientCSV?.map((item) => {
                return [
                    item?.employeeName,
                    item?.date,
                    item?.version,
                    item?.actions
                ];
            });
        } else {
            clientCSV = rcellItem?.map((item) => {
                return [
                    item?.employeeName,
                    item?.date,
                    item?.version,
                    item?.actions
                ];
            });
        }
        return clientCSV;
    }

    const getDatatoFullDownload = (rcellItem) => {
        let clientCSV;
        if (rcellItem) {
            clientCSV = employeeList?.filter(
                (item) => item.employeNameId === rcellItem.employeNameId
            );
            return [
                rcellItem?.employeeName,
                rcellItem?.date,
                rcellItem?.version,
                rcellItem?.actions
            ];
        } else {
            return [
                rcellItem?.employeeName,
                rcellItem?.date,
                rcellItem?.version,
                rcellItem?.actions
            ];
        }
    }

    const downloadForExcel = async (rcellItem, date, emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Activity Report');
        const dates = (isMobile || isLandscape) ? `${date}` : `${startDate}_${endDate}`;

        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            if (rcellItem.length === 1) {
                fileName = `ActivityReport_${rcellItem[0].employeeName}_${dates}`;
            } else {
                fileName = `ActivityReport_All_${date}`;
            }
        } else {
            fileName = `ActivityReport_${rcellItem.employeeName}_${dates}`;
        }
        let name = true;
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name)
    }

    const downloadForPDF = async (rcellItem, date, emailSend) => {
        const clientCSV = getDatatoDownload(rcellItem);
        const dates = (isMobile || isLandscape) ? `${date}` : `${startDate}_${endDate}`;
        let fileName;
        if (Array.isArray(rcellItem)) {
            if (rcellItem.length === 1) {
                fileName = `ActivityReport_${rcellItem[0].employeeName}_${dates}`;
            } else {
                fileName = `ActivityReport_All_${date}`;
            }
        } else {
            fileName = `ActivityReport_${rcellItem.employeeName}_${dates}`;
        }
        let name = true;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name);
    }

    const setDownloadCSVData = (event, done, rcellItem, date) => {
        const clientCSV = getDatatoDownload(rcellItem);

        const reportName = "Activity Report";

        const employeName = Array.isArray(rcellItem) ? rcellItem.length === 1 ? `${rcellItem[0].employeeName}`
            : `All` : `${rcellItem.employeeName}`;
        const reportPeriod = (isMobile || isLandscape) ? `${date}` : `${startDate}_${endDate}`;
        // Create the report details rows
        const reportDetails = [
            [`Employee Name: ${employeName}`],
            [`Report Name: ${reportName}`],
            [`Report Period: ${reportPeriod}`],
            [],
            [...csvHeaders],
            ...clientCSV
        ];
        // Set the CSV data
        setClientListCSV(reportDetails);
        done();
    };


    const downloadForFullPDF = async (emailSend) => {
        const clientCSV = employeeList.map((item) => getDatatoFullDownload(item));
        let empNamefordwonLoad = 'All'
        if (empName.length !== 0 && empName.length !== empNameList.length) {
            // Extracting labels
            const names = empNameList.filter((item) => empName.includes(item.value)).map((emp) => emp.label);
            empNamefordwonLoad = names.join(', ');
        } else if (empName.length === 0) {
            empNamefordwonLoad = 'All'
        }
        let fileName = `ActivityReport_${empNamefordwonLoad}_${startDate}_${endDate}`
        let name = true;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name);
    }

    const downloadForFullExcel = async (emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Activity Report');
        let empNamefordwonLoad = 'All'

        const clientCSV = employeeList.map((item) => getDatatoFullDownload(item));

        if (empName !== 'All') {
            const names = empNameList.filter((item) => empName.includes(item.value)).map((emp) => emp.label);
            empNamefordwonLoad = names.join(', ');
        }
        let fileName = `ActivityReport_${empNamefordwonLoad}_${startDate}_${endDate}`
        let name = true;
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name)
    }

    const handleEmailIconClick = async (rcellItem, date) => {
        const emailSend = true;
        const excelBlob = await downloadForExcel(rcellItem, date, emailSend);
        let name;
        if (Array.isArray(rcellItem)) {
            if (rcellItem.length === 1) {
                name = rcellItem[0]?.employeeName;
            } else {
                name = 'All';
            }
        } else {
            name = rcellItem?.employeeName;
        }

        const Dates = (isMobile || isLandscape) ? `${date}` : `${startDate}`;

        localStorage.setItem("FileName", "ActivityReport");

        localStorage.setItem("ReportsName", "Employee Report");

        localStorage.setItem("Name", name);

        localStorage.setItem("Date", Dates);

        localStorage.setItem("EndDate", endDate);

        const pdfBlob = await downloadForPDF(rcellItem, date, emailSend);
        localStorage.setItem("pdfData", pdfBlob);
        setIsLoading(true);

        // Convert the Excel blob to a base64-encoded string
        const reader = new FileReader();
        reader.readAsDataURL(excelBlob);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("emailData", base64Data);

            // Navigate to the email reports page
            navigate("/emailreports");
        };
    };

    const handelEmailFullReport = async () => {
        const emailSend = true;
        localStorage.setItem("FileName", "ActivityReport");

        localStorage.setItem("ReportsName", "Employee Report");

        let empNamefordwonLoad = 'All'

        if (empName.length !== 0 && empName.length !== empNameList.length) {
            // Extracting labels
            const names = empNameList.filter((item) => empName.includes(item.value)).map((emp) => emp.label);
            empNamefordwonLoad = names.join(', ');
        }

        localStorage.setItem("Name", empNamefordwonLoad);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        setIsLoading(true);
        const fullReport = await downloadForFullPDF(emailSend);
        localStorage.setItem("fullReport", fullReport);

        const fullExcelReport = await downloadForFullExcel(emailSend);
        const reader = new FileReader();
        reader.readAsDataURL(fullExcelReport);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("fullExcelReport", base64Data);

            navigate("/emailreports");
        }
    }

    const handlePdfClick = async (records, date, emailSend) => {
        await downloadForPDF(records, date, emailSend);
    }

    const handleExcelClick = async (records, date, emailSend) => {
        await downloadForExcel(records, date, emailSend);
    }

    const handleCSvClick = async (event, done, records, date) => {
        await setDownloadCSVData(event, done, records, date);
    };

    const handelEmailClicks = async (records, date) => {
        await handleEmailIconClick(records, date);
    }
    const handleEmpNameChange = (e) => {
        const str = e;
        setEmpName(e);
        setEmployeeList(() =>
            employeeListMaster.filter((itme) => {
                let returnFlg = true;
                if (returnFlg) {
                    returnFlg = e.includes(itme.employeNameId);
                }
                return returnFlg;
            })
        );
    };

    useEffect(() => {
        let paramStartDate = "";
        let paramEndDate = "";

        paramStartDate = startDate;
        paramEndDate = endDate;

        getReportListData(paramStartDate, paramEndDate);
    }, [startDate, endDate, empName]);


    const mapWidhtEmpList = (userIds, timeSheet) => {
        getUserListBasedOnIds(userIds, async (result) => {
            const employee = result.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`
            }));
            setEmpNameList([...employee]);

            try {
                const userData = await getUserActivity(userIds);

                const userDataToUpdate = userData.filter(data => userIds.includes(data.userId));

                const userDataMap = {};
                userDataToUpdate.forEach(item => {
                    if (!userDataMap[item.userId]) {
                        userDataMap[item.userId] = [];
                    }

                    const date = moment.unix(item.createdOn.seconds).tz("America/New_York").format('ll');
                    let actions = "";

                    // if (item?.extraParams && item?.extraParams.timesheetId) {
                    //     const timesheetId = item?.extraParams.timesheetId;
                    //     const timesheetData = timeSheet.find(sheet => sheet.id === timesheetId);
                    //     console.log('timesheetData:', timesheetData); 

                    //     if (timesheetData) {
                    //         const checkInDateTime = moment.unix(timesheetData.checkInDateTime.seconds).tz("America/New_York").format("hh:mm A");
                    //         const checkOutDateTime = moment.unix(timesheetData.checkOutDateTime.seconds).tz("America/New_York").format("hh:mm A");

                    //         if (item.type === "CHECKIN") {
                    //             actions = `${item.type} (${checkInDateTime})`;
                    //         } else if (item.type === "CHECKOUT") {
                    //             actions = `${item.type} (${checkOutDateTime})`;
                    //         }
                    //     }
                    // } else {
                    //     const timeFormates = moment.unix(item.createdOn.seconds).tz("America/New_York").format("hh:mm A");
                    //     actions = `${item.type} (${timeFormates})`;
                    // }

                    const timeFormates = moment.unix(item.createdOn.seconds).tz("America/New_York").format("hh:mm A");
                    actions = `${item.type} (${timeFormates})`;

                    userDataMap[item.userId].push({
                        date: date,
                        version: item.version ? `${item.version} (${parseFloat(item.version) < 0 ? 'Web' : item.device || ''})` : '---',
                        actions: actions
                    });
                });

                const employeeListMaster = [];
                for (const userId in userDataMap) {
                    const user = result.find((item) => item.id === userId);
                    const userActivities = userDataMap[userId].map(activity => ({
                        employeeName: `${user.firstName} ${user.lastName}`,
                        employeNameId: userId,
                        date: activity.date,
                        version: activity.version,
                        actions: activity.actions
                    }));
                    employeeListMaster.push(...userActivities);
                }

                const sortedData = sortingFunction(employeeListMaster);
                setEmployeeListMaster(sortedData);
                setEmployeeList(
                    sortedData.filter(item => {
                        if (empName.length > 0) {
                            return empName.some(emp => emp === item.employeNameId);
                        } else {
                            return true;
                        }
                    })
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.employeeName < b.employeeName) return -1;
            if (a.employeeName > b.employeeName) return 1;

            // Parse the date strings into Date objects for correct comparison
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;

            return 0;
        });

        return result;
    };


    const getReportListData = async (startDt, endDt) => {
        const param = {
            startDate: `${moment(startDt).format("YYYY-MM-DD")}T00:00:00`,
            endDate: `${moment(endDt).format("YYYY-MM-DD")}T23:59:59`,
        };

        getTimesheetListReport(
            param,
            (result) => {
                setIsLoading(false);
                const empIds = [];
                for (let i = 0; i < result.length; i++) {
                    if (empIds.indexOf(result[i].userId) === -1) {
                        empIds.push(result[i].userId);
                    }
                }
                mapWidhtEmpList(empIds, result);
            },
            (err) => {
                setIsLoading(false);
                console.log("err>>", err);
            }
        );
    };


    const renderRowCell = (rcellItem, hcellItem, index, rowindex, isMobile, isLandscape) => {
        switch (hcellItem.id) {
            case "employeeName":
                // if (isMobile || isLandscape) {
                //     return null;
                // }
                if (
                    rowindex !== 0 &&
                    employeeList[rowindex - 1].employeeName !== rcellItem.employeeName
                ) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else if (rowindex === 0) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else {
                    return "";
                }
                break;
            case "Action":
                if (isMobile || isLandscape) {
                    if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    if (
                        rowindex !== 0 &&
                        employeeList[rowindex - 1].employeNameId !== rcellItem.employeNameId
                    ) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return "";
                    }
                }
            default:
                return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        }
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch3 editEmp issueAlign">
                <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> <span> Filters </span> </div>
                <div className="mr-12 mv-7 issueicon">
                    <MultiSelectem
                        value={empName}
                        className={`field  ${styles.ddminWidth}`}
                        name={"employeeName"}
                        label={"Employee Name"}
                        onChange={handleEmpNameChange}
                        options={empNameList}
                    />
                </div>
                <div className="mr-12 mv-7 ">
                    <TextField
                        name="startDate"
                        value={startDate}
                        fullWidth={true}
                        size="small"
                        placeholder="love"
                        label={"Start Date"}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        className={`field ${styles.wdate}`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            max: moment().format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                        }}
                    />
                </div>
                <div className="mr-12 mv-7">
                    <TextField
                        name="endDate"
                        value={endDate}
                        fullWidth={true}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        label={"End Date"}
                        variant="outlined"
                        className={`field ${styles.wdate}`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            min: moment(startDate).format("YYYY-MM-DD"),
                            max: moment().add(1, "day").format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Activity Reports"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Reports" },
                        { label: "Employee Reports", to: "/reports/employeereport" }
                    ]}
                    BreadCrumbCurrentPageTitle={"Activity Reports"}
                    showLastSeen={true}
                ></PageTitle>

                <div className={`sectionBox`}>
                    {!detailedView && (
                        <TableToolbar
                            rightComponent={tableRightComponent()}
                        />
                    )}
                    <ResponsiveTable
                        rows={employeeList}
                        getColumns={getColumns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        detailedView={detailedView}
                        setDetailedView={setDetailedView}
                        fileName={"Activity Report"}
                        onClickPdf={handlePdfClick}
                        onClickExcel={handleExcelClick}
                        onClickCsv={handleCSvClick}
                        clientsListCSV={clientsListCSV}
                        onClickEmail={handelEmailClicks}
                    />
                </div>

            {!detailedView && (
                <div className={styles.report}>
                    <div className={styles.download1}>
                        <EmailRoundedIcon />
                        <div>
                            <Link to={employeeList.length !== 0 && "/emailreports"} rel="noopener" >
                                <Button
                                    className={styles.actions}
                                    disabled={employeeList.length === 0}
                                    onClick={() => {
                                        handelEmailFullReport()
                                    }}
                                >
                                    Full Report
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.download1}>
                        <PictureAsPdfIcon />
                        <div>
                            <Button
                                className={styles.actions}
                                disabled={employeeList.length === 0}
                                onClick={() => {
                                    downloadForFullPDF();
                                }}
                            >
                                Download Full Report
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            </Container>
        </>
    );
}

export default ActivityReport;
