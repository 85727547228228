import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import styles from "../dashboard.module.scss";
import { getDocCount } from "../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../services/config";
function EmpRequests(props) {
  //const progressValue = 92;
  const [progress, setProgress] = useState(0);
  const [empreqData, setEmpReqData] = useState([]);
  const [empTotal, setEmpTotal] = useState([]);
  const [empUrg, setEmpUrg] = useState([]);
  const [status, setStatus] = useState("Supplies");
  const statusList = [
    // { label: "All", value: "All" },
    { label: "Supplies", value: "Supplies" },
    // { label: "Clothing", value: "Clothing" },
  ];
  const onChangeInput = (e) => {
    setStatus(e.target.value);
  };
  const updateProgress = (newval) => {
      setProgress(newval);
    
  };


  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.SUPPLIES_CLEANINGS,
      (res) => {
        // console.log("resemp", res);
        setEmpReqData(res ? res : []);
        
        const urgentreq = res.filter(item => item.priority === 'High');
        
        setEmpTotal(res.length);
        setEmpUrg(urgentreq.length)
        if(res.length) {
          updateProgress((100/res.length) * urgentreq.length);
        } else {
          updateProgress(0);
        }
        
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
     
  }, []);

  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe">
            Employee Requests
          </p>
          <SingleSelect
            //   label="status"
            value={status}
            className={`field newonr`}
            name={"status"}
            onChange={(e) => onChangeInput(e)}
            options={statusList}
          />
        </Grid>
        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel signt={"yes"} value={progress} wrapclass="color-empre" />
        </Grid>
      </Grid>
      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Total Requests
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{empTotal}</strong>
            </Grid>
          </Grid>
        </li>

        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Urgent Request
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{empUrg}</strong>
            </Grid>
          </Grid>
        </li>
      </ul>
    </>
  );
}

export default EmpRequests;
