import React from "react";
import { Grid } from "@material-ui/core";
import { getFormatedDate } from "../../../../services/helpers";

const SupplyRequestDetails = (props) => {
  const { requestData } = props;
  return (
    <div className="RequestDetailsWrap bg-gray p-8 m-20">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item className="d-flex flex-space-between w-100 supplysWidth gridBorder bg-gray">
          <h4>Order Id</h4>
          <h4>Order Date</h4>
          <h4>Client Name</h4>
          <h4>Employee Name</h4>
          <h4>Priority</h4>
        </Grid>
        <Grid item className="d-flex flex-space-between w-100 supplysWidth bg-white">
          <p>{requestData?.orderNumber}</p>
          <p>{getFormatedDate(requestData?.dateOfRequest)}</p>
          <p>{requestData?.clientName}</p>
          <p>{requestData?.employeeName}</p>
          <p>{requestData?.priority}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplyRequestDetails;
