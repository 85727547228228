import React, { useState } from 'react';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    CardActions
} from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import styles from "../Reports.module.scss";
import ThemeTable from '../../Table/ThemeTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEnvelope, faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CSVLink, CSVDownload } from "react-csv";

const ResponsiveTable = ({
    rows,
    getColumns,
    isLoading,
    renderRowCell,
    extraHeader,
    detailedView,
    setDetailedView,
    fileName,
    onClickPdf,
    onClickExcel,
    onClickCsv,
    clientsListCSV,
    onClickEmail
}) => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const handleViewDetails = (employeeRecords) => {
        setDetailedView(employeeRecords);
    };

    const groupedByDate = rows.reduce((acc, row) => {
        const dateKey = row.appointmentDate || row.date;
        if (!acc[dateKey]) {
            acc[dateKey] = {};
        }
        if (!acc[dateKey][row.employeNameId]) {
            acc[dateKey][row.employeNameId] = [];
        }
        acc[dateKey][row.employeNameId].push(row);
        return acc;
    }, {});

    const groupsByDate = rows.reduce((acc, row) => {
        const date = row.appointmentDate || row.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(row);
        return acc;
    }, {});

    const sortedDateKeys = Object.keys(groupedByDate)
        .sort((a, b) => new Date(a) - new Date(b));

    return (
        <>
            {detailedView ? (
                <DetailedViewPage
                    employeeRecords={detailedView}
                    getColumns={getColumns}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                    isMobile={isMobile}
                    isLandscape={isLandscape}
                    extraHeader={extraHeader}
                    setDetailedView={setDetailedView}
                />
            ) : (
                !isMobile && !isLandscape ? (
                    <ThemeTable
                        rows={rows}
                        headCells={getColumns()}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        extraHeader={extraHeader}
                    />
                ) : (
                    sortedDateKeys.map((date) => (
                        <Card key={date} className={` ${styles.card}`}>
                            <CardHeader title={date} className={` ${styles.cardHeader}`} />
                            <CardContent className={` ${styles.cardContent}`}>
                                <List>
                                    {Object.keys(groupedByDate[date]).map((empId, index) => {
                                        const employeeRecords = groupedByDate[date][empId];
                                        return (
                                            <React.Fragment key={`${empId}-${index}`}>
                                                <ListItem className={` ${styles.listItemm}`}>
                                                    <ListItemText primary={employeeRecords[0].employeeName} />
                                                    <KeyboardArrowRightSharpIcon
                                                        onClick={() => handleViewDetails(employeeRecords)}
                                                    />
                                                </ListItem>
                                                 <Divider />
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            </CardContent>
                            <CardActions style={{justifyContent:"space-between"}} className={` ${styles.listItemm}`}>
                                <Typography variant="body2">Actions</Typography>
                                <div className={`${styles.icons}`}>
                                    <FontAwesomeIcon icon={faEnvelope} size='lg' onClick={() => onClickEmail(groupsByDate[date], date)}/>
                                    <FontAwesomeIcon icon={faFilePdf} size='lg' onClick={() => onClickPdf(groupsByDate[date], date)} />
                                    <CSVLink
                                        data={clientsListCSV}
                                        filename={`${fileName}_${date}.csv`}
                                        asyncOnClick={true}
                                        onClick={(event, done) => onClickCsv(event, done, groupsByDate[date], date)}
                                        >
                                        <FontAwesomeIcon icon={faFileCsv} size='lg' className='color-primary'/>
                                    </CSVLink>
                                    <FontAwesomeIcon icon={faFileExcel}  size='lg' onClick={() => onClickExcel(groupsByDate[date], date)}/>
                                </div>
                            </CardActions>
                        </Card>
                    ))
                )
            )}
        </>
    );
};

const DetailedViewPage = ({ employeeRecords, getColumns, isLoading, renderRowCell, extraHeader, isMobile, isLandscape, setDetailedView }) => {
    return (
        <>
            <Card>
                <CardHeader
                    title={employeeRecords[0].employeeName}
                    className={` ${styles.cardHeader}`}
                    action={
                        <Button
                            className={styles.button}
                            size={"small"}
                            variant="contained"
                            color='primary'
                            onClick={() => setDetailedView(null)}>
                            BACK
                        </Button>
                    }
                >
                </CardHeader>
                <ListItem className={` ${styles.listItemm2}`}>
                    <ListItemText
                        primary={employeeRecords[0].appointmentDate || employeeRecords[0].date}
                        className={` ${styles.cardHeader} ${styles.listText}`}
                    />
                </ListItem>
                <CardContent className='MuiCardContent-root'>
                    <ThemeTable
                        rows={employeeRecords}
                        headCells={getColumns()}
                        hidePagination={true}
                        isLoading={isLoading}
                        noBorder={true}
                        divider={true}
                        renderRowCell={(rcellItem, hcellItem, index, rowindex) =>
                            renderRowCell(rcellItem, hcellItem, index, rowindex, isMobile, isLandscape)
                        }
                        extraHeader={extraHeader}
                    />
                </CardContent>
            </Card>
            <Button
                className={styles.button2}
                size={"small"}
                variant="contained"
                color='primary'
                onClick={() => setDetailedView(null)}>
                BACK
            </Button>
        </>
    );
};

export default ResponsiveTable;
