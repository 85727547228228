/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../../hooks/commonContext";
import { Container, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { Columns } from "./manageSuppliesRequestTableColumns";
import {
  getSupplyOrderList, getCategories, addNotes, fetchNotes, updateSupplyItem, updateStatus,
  getSupplyOrderListBasedOnId
} from "../../../../services/apiservices/supplies";
import SupplyRequestDetails from "./SupplyRequestDetails";
import moment from "moment";
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getClientsListbasedonIds } from "../../../../services/apiservices/client";
import { getEmployeesListBasedOnId } from "../../../../services/apiservices/employee";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';

const NotesSection = ({ notes, onAddNote, employee, createdBy, orderId, setNotes }) => {
  const [newNote, setNewNote] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleAddNote = async () => {
    if (newNote.trim()) {
      const note = {
        createBy: createdBy,
        note: newNote.trim(),
        orderId: orderId,
      };
      try {
        const addedNote = await addNotes(note);
        onAddNote && onAddNote(addedNote);
        fetchNotes(orderId, (fetchedNotes) => {
          setNotes(fetchedNotes);
        });
      } catch (error) {
        console.error('Error adding note: ', error);
      }

      setNewNote('');
    }
  };

  const handleCancel = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setDialogOpen(false);
    navigate(-1);
    setNewNote('');
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = moment(timestamp.seconds * 1000);
    return date.format('MMM D, YYYY');
  };

  const createBy = (id, employees) => {
    const employee = employees.find(item => item.value === id);
    const data = employee ? employee.label : "";
    return data;
  };

  return (
    <>
      <strong> Notes </strong>
      <hr className="note-divider mb-25" />
      <div className="notes-section">
        {notes[0]?.message ? (
          <p>No Notes Available</p>
        ) : (
          notes.map((note, index) => (
            <div key={index} className="note">
              <p>{note?.note}</p>
              <p>
                <strong>Date:</strong> {formatDate(note?.createdAt)}{' '}
                <strong className="ml-10">Created By:</strong> {createBy(note?.createBy, employee)}
              </p>
              {index < notes.length - 1 && <hr className="note-divider" />}
            </div>
          ))
        )}
        <div className="note-input">
          <TextField
            label="Add Notes"
            multiline
            rows={3}
            variant="outlined"
            className="mt-15"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            fullWidth
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddNote}
            className="mt-15"
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            className="mt-24 m-10"
          >
            Cancel
          </Button>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};



const SupplyStatus = ({ rcellItem, rowIndex, onUpdateStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectStatus = (status) => {
    onUpdateStatus(status);
    handleClose();
  };

  const availableStatuses = ['Ordered', 'Shipped', 'Delivered', 'Back Ordered'].filter(status => status !== rcellItem.status);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{rcellItem?.status}</span>
      <IconButton
        size="small"
        onClick={handleClick}
        color="primary"
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableStatuses.map(status => (
          <MenuItem key={status} onClick={() => handleSelectStatus(status)}>{status}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};


const ManageSuppliesRequestDetails = (props) => {
  const commonContext = useContext(CommonContext);
  const { requestId } = useParams();
  const [requestData, setRequestData] = React.useState({});
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [notes, setNotes] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(null);
  const [openDatePickerId, setOpenDatePickerId] = useState(null);
  const [categories, setCategories] = useState({});
  const [empName, setEmployeeName] = useState();

  const getRequestData = (requestId) => {
    if (typeof requestId !== "undefined" && requestId !== "") {
      commonContext?.setLoader(true);

      getSupplyOrderListBasedOnId(
        requestId,
        async (res) => {
          if (!res) {
            setRequestData({});
            setAllSupplies([]);
            commonContext?.setLoader(false);
            return;
          }

          const fetchAndStoreCategories = async (items) => {
            for (const item of items) {
              if (item?.parentCategoryId) {
                await getCategories(item?.parentCategoryId, (categoriesData) => {
                  const category = categoriesData.find((cate) => cate?.id === item?.parentCategoryId);
                  const title = category?.title;
                  if (title) {
                    setCategories((prevCategories) => ({
                      ...prevCategories,
                      [item.parentCategoryId]: title,
                    }));
                  }
                });
              }
            }
          };

          await fetchAndStoreCategories(res?.supplyItems);

          let clientIds = [res.clientId];
          let empIds = [res.createdBy];

          let clintsData = [];
          await getClientsListbasedonIds(clientIds, (result) => {
            const clients = result.map((item) => {
              return { value: item?.id, label: item?.clientName };
            });
            clintsData = clients;
          });

          let employeeData = [];
          await getEmployeesListBasedOnId(empIds, (result) => {
            const employees = result.map((item) => {
              return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
            });
            setEmployeeName(employees);
            employeeData = employees;
          });

          const client = clintsData.find(client => client?.value === res?.clientId);
          const clientName = client ? client.label : 'Unknown Client';

          const employee = employeeData.find(emp => emp?.value === res?.createdBy);
          const employeeName = employee ? employee.label : 'Unknown Employee';

          let priorityLabel;
          switch (res.priority) {
            case "1":
              priorityLabel = "High";
              break;
            case "2":
              priorityLabel = "Medium";
              break;
            case "3":
              priorityLabel = "Low";
              break;
            default:
              priorityLabel = "Unknown";
          }

          const finaleData = {
            ...res,
            priority: priorityLabel,
            orderNumber: String(res.orderNumber).padStart(6, '0'),
            clientName: clientName,
            employeeName: employeeName
          };

          setRequestData(finaleData);
          setAllSupplies(finaleData?.supplyItems);

          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      setRequestData({});
      setAllSupplies([]);
      commonContext?.setLoader(false);
    }
  };

  useEffect(() => {
    getRequestData(requestId);
  }, [requestId]);

  useEffect(() => {
    const loadNotes = async () => {
      if (!requestData?.id) {
        setNotes([{ message: 'No ID provided' }]);
        return;
      }

      fetchNotes(
        requestData.id,
        (fetchedNotes) => {
          setNotes(fetchedNotes);
        },
        (error) => {
          console.error('Error fetching notes: ', error);
          setNotes([{ message: 'Error fetching notes' }]);
        }
      );
    };

    loadNotes();
  }, [requestData?.id])

  const handleNote = (newNote) => {
    setNotes([...notes, newNote]);
  };

  const handleUpdateStatus = async (requestData, rcellItem, newStatus) => {
    try {
      await updateStatus(
        requestData.id,
        rcellItem.id,
        newStatus,
        (data) => {
          if (data) {
            getRequestData(requestId);
          }
        },
        (error) => {
          console.error('Failed to update status:', error);
        }
      );
    } catch (error) {
      console.error('Error in handleUpdateStatus:', error);
    }
  };

  const handleDateIconClick = (id, date) => {
    if (openDatePickerId === id) {
      setShowDatePicker(false);
      setOpenDatePickerId(null);
    } else {
      setOpenDatePickerId(id);
      setTempDate(date ? dayjs(date) : dayjs());
      setShowDatePicker(true);
    }
  };

  const handleDateChangeConfirmed = (id, date) => {
    updateDeliveryDate(requestData.id, id, date);
    setShowDatePicker(false);
  };

  const handleDateChangeCancelled = () => {
    setShowDatePicker(false);
  };

  const updateDeliveryDate = async (requestId, itemId, deliveryDate) => {
    try {
      await updateSupplyItem(
        requestId,
        itemId,
        moment(deliveryDate).format("MM-DD-YYYY"),
        (data) => {
          if (data) {
            getRequestData(requestId);
          }
        },
        (error) => {
          console.error("Failed to update status:", error);
        }
      );
    } catch (error) {
      console.error("Error in handleUpdateStatus:", error);
    }
  };

  const formatDeliveryDate = (timestamp) => {
    const date = timestamp ? new Date(timestamp.seconds * 1000) : null;
    return date ? moment(date).format("MMM DD, YYYY") : "---";
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "categories":
        if (rowindex !== 0 && categories[rcellItem?.parentCategoryId] !== categories[requestData.supplyItems[rowindex - 1]?.parentCategoryId]) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        }
        else if (rowindex === 0) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        } else {
          return "";
        }
        break;
      case "supplies":
        return `${rcellItem?.title} (${rcellItem?.qty})`;
        break;
      case "deliveryDate":
        if (requestData.status === "Approved") {
          return (
            <>
              <span>
                {rcellItem?.deliveryDate ? formatDeliveryDate(rcellItem?.deliveryDate) : "---"}
              </span>
              <IconButton className="p-5" onClick={() => handleDateIconClick(rcellItem?.id, rcellItem?.deliveryDate)}>
                <DateRangeIcon className="color-primary" style={{ padding: 0 }} fontSize="small" />
              </IconButton>
              {showDatePicker && openDatePickerId === rcellItem?.id && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      value={tempDate}
                      onChange={(date) => setTempDate(date ? dayjs(date) : null)}
                      onAccept={() => handleDateChangeConfirmed(rcellItem?.id, tempDate)}
                      onClose={() => {
                        handleDateChangeCancelled();
                        setOpenDatePickerId(null);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      format="YYYY-MM-DD"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            </>
          );
        } else {
          return "---";
        }
        break;
      case "status":
        return (
          <SupplyStatus
            rcellItem={rcellItem}
            rowIndex={rowindex}
            onUpdateStatus={(status) => handleUpdateStatus(requestData, rcellItem, status)}
          />
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Supplies Manage Request", to: "/supplies/cleaning/manage" },
          ]}
          BreadCrumbCurrentPageTitle={"View Supplies Request"}
        />

        <div className={`sectionBox`}>

          <SupplyRequestDetails requestData={requestData} />

          <div className="p-18">

            <ThemeTable
              rows={allSupplies}
              headCells={Columns}
              hidePagination={true}
              renderRowCell={renderRowCell}
              supplies={true}
              requestData={requestData}
              categories={categories}
            />

            <NotesSection
              notes={notes}
              onAddNote={handleNote}
              employee={empName}
              createdBy={requestData?.createdBy}
              orderId={requestData?.id}
              setNotes={setNotes}
            />

          </div>
        </div>
      </Container>
    </>
  );
};

export default ManageSuppliesRequestDetails;
