import {  faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Link, Typography, TextField } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import React, { useEffect, useState } from "react";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import ThemeTable from "../../../components/Table/ThemeTable";
import { getEmployeeSummary } from "../../../services/apiservices/employee";
import styles from "../dashboard.module.scss";
import { getDocCount } from "../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../services/config";
import moment from 'moment';

const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSortable: true,
  },
  {
    id: "item",
    numeric: false,
    disablePadding: false,
    label: "Item",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: true,
    label: "Status",
    isSortable: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    isSortable: true,
  },

  {
    id: "viewlink",
    numeric: false,
    isEditRow:true,
    disablePadding: true,
    label: "Action",
    rowclassName: "",
    className: "pr-30",
  },
];

const Rows = [
  {
    name: "Liam Miller",
    item: "MTA",
    status: "Scheduled",
    date: "June 12, 2021",
  },
  {
    name: "Diane Nolan",
    item: "ABC",
    status: "Worker(s) Late",
    date: "June 12, 2021",
  },
  {
    name: "Kimberly Berry",
    item: "Qwerty",
    status: "Work Started",
    date: "June 12, 2021",
  },
  {
    name: "Luke lnce",
    item: "XYZ",
    status: "Scheduled",
    date: "June 12, 2021",
  },
];

function EmployeeSummary(props) {
  const [sort, setSort] = useState("Newest");
  const [employeeList, setEmployeeList] = useState([Rows]);
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));

  const handleDateChange = (newValue) => {
    setFilterDate(newValue.target.value);
  };
  const sortBy = [
    { label: "Newest", value: "Newest" },
    { label: "Oldest", value: "Oldest" },
  ];
  const onChangeInput = (e) => {
    setSort(e.target.value);
  };

  // const getSummaryData = () => {
  //   getEmployeeSummary({},(res)=>{
  //     if(res){
  //       let setallclientData = [
  //         ...res?.map((rcitem) => {
            
  //           return {
  //             ...rcitem,
  //             name: rcitem.firstName + ' ' + rcitem.lastName,
  //           };
  //         }),
  //       ];
  //       setEmployeeList(setallclientData);
  //     }
  //   },(reserror)=>{

  //   })

    
  // }
  useEffect(()=>{
    getDocCount(
      COLLECTIONS.SUPPLIES_CLEANINGS,
      (res) => {
        // console.log("reslist", res);
        res.forEach(function(e) {
          e['name'] = e.employee.name;
          e['viewlink'] = `/supplies/${e.item}/request/${e.id}`;
          e['date'] = moment(e.dateOfRequest).format('ll');
      });
      // console.log('newres',res)
        setEmployeeList(res ? res : []);
        
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  },[])

  const getviewTag = (cellData) => {
    

    return <a className='link' href={cellData['viewlink']}>View</a>;
  }


  const getStatusTag = (cellData) => {
    
    let status = styles.statusYellow;
    let statusColor = styles.statusYellowcolo;
    if(cellData['priority']==='High'){
      status = styles.statusRed;
      statusColor = styles.statusRedcolo;
    }
    
    
    
    return <div className={`${statusColor}`}><div className={`${styles.stausCircle} ${status}`}></div>{cellData['priority']}</div>
  }

  const renderRow = (cellData, colData, cellIndex, RowIndex) => {
    if(cellIndex === 0) {
      
      return <div className="boldfont">{cellData[colData.id] ? cellData[colData.id] : '' }</div>;
    } 
    else if(cellIndex === 4) {
      
      return getviewTag(cellData);  
    } else if (cellIndex === 2){
      return getStatusTag(cellData);
    }
      
    
    
    return cellData[colData.id] ? cellData[colData.id] : '';
    
  }

  return (
    <>
      <Grid container alignItems="center" spacing={2} className="mb-10">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            <PersonOutlineOutlinedIcon className="color-primary mr-10 svg-icon" />
            Employee Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="d-flex groupsearch float-right">
            <div className=" mh-12">
              <TextField
                name="date"
                value={filterDate}
                fullWidth={true}
                size="small"
                variant="outlined"
                className={`field`}
                type={"date"}
                inputProps={{
                  className: "p-10",
                  min: moment().add(-30, "days").format("YYYY-MM-DD"),
                  max: moment().add(15, "days").format("YYYY-MM-DD"),
                }}
                onChange={handleDateChange}
              />
            </div>
            <div className=" mh-12">
              <SingleSelect
                value={sort}
                className={`field`}
                name={"sort"}
                onChange={(e) => onChangeInput(e)}
                options={sortBy}
              />
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={2} className="">
          <SingleSelect
            //   label="status"
            value={sort}
            className={`field`}
            name={"sort"}
            onChange={(e) => onChangeInput(e)}
            options={sortBy}
          />
        </Grid> */}
      </Grid>
      <div className={`sectionBox nospacing tabletd shadow ${styles.summaryTableWrap}`}>
        <ThemeTable
          rows={employeeList}
          headCells={Columns}
          hidePagination={true}
          renderRowCell={renderRow}
          //renderRowCell={renderRow}
          // selectedRows={selectedRows}
        />
        <p className="text-center">
         <Link className="link" href="/supplies/cleaning">View all Employee Summary</Link></p>
    
      </div>
    </>
  );
}

export default EmployeeSummary;
