/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import moment from "moment";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../../hooks/commonContext";
import {
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
} from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { Columns } from "./suppliesRequestTableColumns";
import TableToolbar from "../../../../components/Table/TableToolbar";
import confirmWrapper from "../../../../components/DialogComponent/confirmnew";
import { useEffect } from "react";
import {
  getSupplies,
  getSuppliesByClientItems,
  // saveRejectionReson,
  updateSuppliesItemsData,
} from "../../../../services/apiservices/supplies";
import confirm from "../../../../components/DialogComponent/confirm";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import { getFormatedDate } from "../../../../services/helpers";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import SupplyAlertInfo from "./SupplyAlertInfo";
import SuppliesRequestRejectNote from "./SuppliesRequestRejectNote";
const SuppliesRequestDetails = (props) => {
  const commonContext = useContext(CommonContext);
  let history = useNavigate();
  const { requestId } = useParams();
  const [requestData, setRequestData] = React.useState({});
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [saveData, setSaveData] = React.useState([]);
  const [saveStatus, setSaveStatus] = React.useState("");

  const OnSuccessGetSupplies = async (res) => {
    if (res?.status === true) {
      let allSuppliyItems = res?.SupplieItems;
      if (allSuppliyItems) {
        setAllSupplies(allSuppliyItems);
        if (allSuppliyItems.length > 0) {
          let clients = [],
            items = [];
          allSuppliyItems?.map((slitem) => {
            clients.push(slitem?.clientName);
            items.push(slitem?.itemName);
            return slitem;
          });
          var uniquecliets = clients.filter((v, i, a) => a.indexOf(v) === i);
          var uniqueItems = items.filter((v, i, a) => a.indexOf(v) === i);
          await getSuppliesByClientItems({ Clients: uniquecliets }, (res) => {
            if (res) {
              let actualdata = res?.filter(
                (resItem) => resItem?.id !== requestId
              );
              actualdata?.map((actualdataItem) => {
                var intersectionsItems = uniqueItems.filter(
                  (e) => actualdataItem?.supplyItemsList.indexOf(e) !== -1
                );
                if (intersectionsItems?.length > 0) {
                  let newallSuppliyItems = allSuppliyItems?.map((asitem) => {
                    let lastRequestDate = "";
                    if (
                      actualdataItem?.supplyItemsList.indexOf(
                        asitem?.itemName
                      ) !== -1
                    ) {
                      lastRequestDate = actualdataItem?.dateOfRequest;
                    }
                    return {
                      ...asitem,
                      lastRequestedBy: actualdataItem?.employee.name,
                      lastRequestDate: lastRequestDate,
                    };
                  });
                  setAllSupplies(newallSuppliyItems);
                }
                return actualdataItem;
              });
            }
          });
        }
      }
      setRequestData(res?.data);
    }
  };
  const getRequestData = (requestId) => {
    if (typeof requestId !== "undefined" && requestId !== "") {
      commonContext?.setLoader(true);
      getSupplies(
        requestId,
        async (res) => {
          console.log(res);
          OnSuccessGetSupplies(res);
          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      setRequestData({});
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    getRequestData(requestId);
  }, [requestId]);

  const tableLeftComponent = () => {
    return (
      <Typography className={`tableTitle w-100 strong`} variant="body1">
        {`Supplies Request #${requestId} - ${
          requestData?.employee?.name ? requestData?.employee?.name : ""
        }`}
      </Typography>
    );
  };

  const tableRightComponent = () => {
    return (
      <Typography className={`tableTitle strong`} variant="body1">
        {`Date of Request ${
          requestData?.dateOfRequest
            ? moment(requestData?.dateOfRequest).format("MMMM D, YYYY")
            : ""
        }`}
      </Typography>
      //   <div className="d-flex flex-center w-100"></div>
    );
  };

  const AddToSaveData = (rowItem, data) => {
    setAllSupplies([
      ...allSupplies.map((sitem) =>
        sitem.id === rowItem.id ? { ...sitem, ...data } : sitem
      ),
    ]);

    let newsaveData = saveData;
    if (saveData.some((sitem) => sitem?.id === rowItem?.id)) {
      newsaveData = saveData?.map((sfitem) =>
        sfitem?.id === rowItem?.id ? { ...sfitem, ...data } : sfitem
      );
    } else {
      newsaveData.push({ ...rowItem, ...data });
    }
    setSaveData(newsaveData);
    console.log("fsfssfsf", newsaveData);
  };

  const handlestatusChange = (event, rowItem) => {
    console.log("handlestatusChange");
    const {
      target: { value },
    } = event;
    if (value === "Approve") {
      setSaveStatus("Approved");
    }

    if (
      value === "Approve" &&
      rowItem?.status === "Reject" &&
      rowItem?.rejection?.saved === true
    ) {
      confirm(
        <div className="ph-12">
          <p>
            By Click on Confirm button you will delete the last reject response
            saved for this item.{" "}
          </p>
          <p>if you wish to proceed, click confirm</p>
        </div>,
        { okLabel: "Confirm", titleWrapClass: "bg-danger", title: "Alert" }
      ).then(() => {
        AddToSaveData(rowItem, { status: value, rejection: { saved: false } });
      });
    } else {
      AddToSaveData(rowItem, { status: value });
    }
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "itemName":
        return `${rcellItem[hcellItem?.id]} (${rcellItem["quantity"]})`;
      case "lastRequestDate":
        var a = moment(rcellItem[hcellItem?.id]);
        var b = moment(requestData?.dateOfRequest);
        var diff = a.diff(b, "days"); // 1

        let showalert =
          diff > 15 && diff <= 30
            ? "alert"
            : diff >= 0 && diff <= 15
            ? "danger"
            : "";
        return (
          <>
            {getFormatedDate(rcellItem[hcellItem?.id])}
            {rcellItem[hcellItem?.id] &&
              rcellItem[hcellItem?.id] !== "" &&
              showalert !== "" && (
                <DialogComponent
                  buttonWrapClassName={"d-inline"}
                  buttonClassName={`flatbutton iconButton color-${showalert}`}
                  buttonProps={{ size: "small" }}
                  buttonLabel={<WarningIcon className={`fs-16`} />}
                  modaltitlewrapclasses={`bg-${showalert}`}
                  ModalTitle={
                    showalert === "alert"
                      ? "Warning"
                      : showalert === "danger"
                      ? "Alert"
                      : ""
                  }
                  ModalContent={
                    <SupplyAlertInfo
                      title={`This item(s) was Requested within the last 15 days by a ${rcellItem["lastRequestedBy"]}`}
                    />
                  }
                />
              )}
          </>
        );
      case "action":
        let status = rcellItem["status"];
        return (
          <>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="status"
                name="requeststatus"
                value={status ? status : ""}
                onChange={(e) => handlestatusChange(e, rcellItem)}
                className="d-flex flex-direction-row"
              >
                <FormControlLabel
                  value={"Approve"}
                  control={<Radio size="small" color="primary" />}
                  label={"Approve"}
                />
                {/* <FormControlLabel
                  value={"Reject"}
                  control={<Radio size="small" color="primary" />}
                  label={"Reject"}
                /> */}
                {/* {status === "Reject" && ( */}
                <DialogComponent
                  buttonWrapClassName={"d-inline"}
                  buttonClassName={`flatbutton iconButton`}
                  buttonProps={{ size: "small" }}
                  buttonLabel={
                    <>
                      <FormControlLabel
                        value={"Reject"}
                        control={<Radio size="small" color="primary" />}
                        label={"Reject"}
                      />
                      {status === "Reject" && (
                        <AnnouncementOutlinedIcon
                          className={`color-primary mt-3 fs-16`}
                        />
                      )}
                    </>
                  }
                  ModalTitle={"Resone to Reject supply"}
                  ModalContent={
                    <SuppliesRequestRejectNote
                      requestId={requestId}
                      rejection={rcellItem?.rejection}
                      supplieItem={rcellItem}
                      onAddSuccess={(rejection) => {
                        AddToSaveData(rcellItem, { rejection });
                      }}
                    />
                  }
                />
                {/* )} */}
              </RadioGroup>
            </FormControl>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const OnFinalSaveData = async() => {
    if(saveData?.length <= 0) {
      if (
        await confirm(
           "Please select either Approve or Reject",
          { titleWrapClass: "bg-danger", title: "Alert", cancel: undefined }
        )
      ) {
        console.log("alert");
      }
      return;
    }
    if (saveData?.length > 0) {
      let rejectValid = true;
      let approveOrRejectValid = true;
      console.log(saveData);
      saveData?.map(async (sDataItem) => {
        let tempSDataItem = sDataItem;
        if (sDataItem?.status !== "Reject" && sDataItem?.status !== "Approve") {
          approveOrRejectValid = false;
        }
        if (
          sDataItem?.rejection === "Reject" &&
          (!sDataItem?.rejection?.message || !sDataItem?.rejection?.reason)
        ) {
          rejectValid = false;
        }

        if (sDataItem?.rejection !== "") {
          tempSDataItem = {
            ...tempSDataItem,
            rejection: {
              ...tempSDataItem?.rejection,
              saved:
                typeof tempSDataItem?.rejection?.saved !== "undefined"
                  ? tempSDataItem?.rejection?.saved
                  : true,
            },
          };
        }
        if (!rejectValid || !approveOrRejectValid) {
          if (
            await confirm(
              !approveOrRejectValid
                ? "Please select either Approve or Reject"
                : "Please enter reason for Reject",
              { titleWrapClass: "bg-danger", title: "Alert", cancel: undefined }
            )
          ) {
            console.log("alert");
          }

          return;
        }
        updateSuppliesItemsData(
          requestId,
          sDataItem?.id,
          tempSDataItem,
          saveStatus,
          (res) => {
            history("/supplies/cleaning");
          },
          (resError) => {}
        );
        return sDataItem;
      });
    }
    /*
    if (value === "Approve" && rowItem?.status === "Reject") {
      confirm(
        <div className="ph-12">
          <p>
            By Click on Confirm button you will delete the last reject response
            saved for this item.{" "}
          </p>
          <p>if you wish to proceed, click confirm</p>
        </div>,
        { okLabel: "Confirm", titleWrapClass: "bg-danger", title: "Alert" }
      ).then(() => {
        updateSuppliesItemsData(
          requestId,
          rowItem?.id,
          { status: value },
          (res) => {
            setAllSupplies([
              ...allSupplies.map((sitem) =>
                sitem.id === rowItem.id ? { ...sitem, status: value } : sitem
              ),
            ]);
          },
          (resError) => {}
        );
      });
    } else {
      confirm(
        <>
          Are you Sure you want to <strong>{value}</strong> This Request Item?
        </>,
        { title: "Alert" }
      ).then(() => {
        updateSuppliesItemsData(
          requestId,
          rowItem?.id,
          { status: value },
          (res) => {
            setAllSupplies([
              ...allSupplies.map((sitem) =>
                sitem.id === rowItem.id ? { ...sitem, status: value } : sitem
              ),
            ]);
          },
          (resError) => {}
        );
      });
    }*/
  };
  const OnCancelSave = () => {
    confirm(<>Are you sure you want to cancel this entry?</>, {
      title: "Alert",
    }).then(() => {
      history("/supplies/cleaning");
    });
  };
  const filteredList = allSupplies;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies Request"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Supplies", to: "/supplies/cleaning" },
            // { label: "Supplies Request", to: "/" },
          ]}
          // showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <TableToolbar
            title=""
            rightComponent={tableRightComponent()}
            leftComponent={tableLeftComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
          />
          <div className="d-flex flex-center flex-justify-center mt-15">
            <Button
              color="primary"
              className="flatbutton theme-button mh-6"
              variant={"contained"}
              onClick={OnFinalSaveData}
            >
              Submit
            </Button>
            <Button
              className="flatbutton theme-button mh-6"
              variant={"contained"}
              onClick={OnCancelSave}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};
export default SuppliesRequestDetails;
