/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, TextField } from "@material-ui/core";
import SingleSelect from "../../../../components/SelectField/SingleSelect";

const SuppliesRequestRejectNote = (props) => {
  const [reasonData, setreasonData] = React.useState({});
  const [errors, setErrors] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const OnChnagereasonData = (event) => {
    const {
      target: { name, value },
    } = event;
    setreasonData({ ...reasonData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const onAdd = async () => {
    let errormessages = errors;
    let flag = true;
    const { reason, message } = reasonData;
    if (typeof reason === "undefined" || reason === "") {
      flag = false;
      errormessages = {
        ...errormessages,
        reason: "you have not selected any reason ",
      };
    } else {
      errormessages = {
        ...errormessages,
        reason: "",
      };
    }
    if (typeof message === "undefined" || message === "") {
      flag = false;
      errormessages = {
        ...errormessages,
        message: "you cant leave description  box empty",
      };
    } else {
      errormessages = {
        ...errormessages,
        message: "",
      };
    }

    if (flag !== false) {
      setIsLoading(true);
      let rejection = {
        reason: reasonData?.reason,
        message: reasonData?.message,
      };
      props?.onAddSuccess(rejection);
      props?.onClose();
    }
    setErrors({ ...errormessages });
  };

  let allreasons = [
    { label: "Select", value: "" },
    { label: "Request is to early", value: "Request is to early" },
    {
      label: "Product order recently approved",
      value: "Product order recently approved",
    },
    { label: "Low Inventory", value: "Low Inventory" },
    { label: "Administration Discretion", value: "Administration Discretion" },
  ];
  return (
    <div className="p-15">
      {props?.rejection?.saved === true ? (
        <>
          <h4>Reason: {props?.rejection?.reason}</h4>
          <p>Message: {props?.rejection?.message}</p>
        </>
      ) : (
        <>
          <div className="formFieldwrap">
            <SingleSelect
              label="Reason"
              value={reasonData?.reason ? reasonData?.reason : ""}
              className={`field`}
              name={"reason"}
              onChange={OnChnagereasonData}
              options={[...allreasons]}
            />
          </div>
          {typeof errors?.reason !== "undefined" && errors?.reason !== "" && (
            <span className="small error color-danger">{errors?.reason}</span>
          )}

          <div className="formFieldwrap">
            <TextField
              size="small"
              multiline={true}
              rows={4}
              name="message"
              id="message"
              label="Reason Message"
              variant="outlined"
              placeholder="reason Message"
              onChange={OnChnagereasonData}
              value={reasonData?.message ? reasonData?.message : ""}
            />
          </div>
          {typeof errors?.message !== "undefined" && errors?.message !== "" && (
            <span className="small error color-danger">{errors?.message}</span>
          )}
          <div className="mv-12 text-right">
            <Button
              className="flatbutton"
              type="button"
              variant={"contained"}
              onClick={onAdd}
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Loading..." : "Save"}
            </Button>
            <Button
              className="flatbutton ml-10"
              type="button"
              variant={"contained"}
              disabled={isLoading ? true : false}
              onClick={props?.onClose}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default SuppliesRequestRejectNote;
